<template>
  <div class="container-custom" :style="styleObject">
    <div class="content-custom" v-on:scroll.passive="handleScroll($event)">
      <Header></Header>
      <div class="order-info">
        <div class="category-list">
          <div
            v-for="(category, index) in categories"
            :key="index"
            class="category-tag"
            :id="'category-tag-' + category.id"
            :class="[
              { active: categorySelect.id == category.id },
              'tag-name-category-' + category.id,
            ]"
            @click="selectCategory(category, index)"
          >
            <div class="wrap-category">
              <img class="c-img" :src="category.img_version" alt="error" />
              <img
                v-if="category.is_feature"
                class="custom-star"
                src="@/assets/img/star.png"
                alt="logo"
              />
            </div>
            <div class="c-name mt-1">
              {{ category.second_name }}
            </div>
          </div>
        </div>
        <div class="content" v-if="isLoadDone">
          <div class="category-detail" v-if="categorySelect">
            <div>
              <!-- <div
                class="tag-name-category"
                :id="'tag-name-category-' + categorySelect.id"
                style="align-items: center"
              >
                <div class="d-flex font-weight-bold">
                  {{ categorySelect.second_name }}
                  <img
                    v-if="categorySelect.is_feature"
                    class="ms-2 me-3 mb-0"
                    style="width: 20px; height: 20px"
                    src="@/assets/img/star.png"
                    alt="logo"
                  />
                </div>
                <div class="category-description" v-if="categorySelect.details">
                  {{ categorySelect.details }}
                </div>
              </div> -->
              <div class="item-list">
                <div id="show" class="position-absolute fly-cart">
                  <img class="img-fluid" src="" alt="" />
                </div>
                <div class="row ms-0 me-0 row-flex">
                  <div
                    class="col-6"
                    v-for="(item, index2) in categorySelect.items"
                    :key="index2"
                    @click="
                      gotoOrderDetail(
                        categorySelect.id,
                        item.id,
                        item.stock_balance,
                        item.is_not_available,
                        item.stock_enable_balance,
                        item
                      )
                    "
                  >
                    <div
                      class="col-12 item-info h-100"
                      :class="[
                        'item-img-' + categorySelect.id + '-' + item.id,
                        {
                          'item-not-available': !checkAvailableItem(item),
                        },
                      ]"
                      style="position: relative"
                    >
                      <img
                        :src="item.image"
                        alt="error"
                        style="width: 100%"
                        class="item-img"
                      />
                      <div>
                        <div
                          class="item-name"
                          style="
                            line-height: normal;
                            font-size: 15px;
                            font-weight: bold;
                          "
                        >
                          {{ item.item_name }}
                        </div>
                        <div class="category-description">
                          {{ item.description }}
                        </div>
                        <!-- <div
                        style="
                          display: flex;
                          gap: 3px;
                          margin-top: 5px;
                          margin-bottom: 5px;
                          align-items: center;
                          justify-content: center;
                        "
                      >
                        <div
                          class="txt_popular_grid"
                          v-if="checkAndReturnPopular(item.tags)"
                        >
                          {{ checkAndReturnPopular(item.tags) }}
                        </div>
                        <div
                          class="txt_popular_grid"
                          v-if="checkAndReturnSignature(item.tags)"
                        >
                          {{ checkAndReturnSignature(item.tags) }}
                        </div>
                      </div> -->
                        <div class="position-relative mt-3">
                          <div class="change-item-qty add-more-item">
                            <div class="d-flex justify-content-center">
                              <div
                                v-if="
                                  checkAvailableItem(item) &&
                                  (getTotalQuantityById(item.id) ||
                                    categorySelect.quantity) > 0 &&
                                  item.isHaveModifierOptionGroups == 0 &&
                                  !item.openingEditQuantity
                                "
                                style="
                                  border: 0px;
                                  width: 30px;
                                  height: 30px;
                                  border: 1px solid var(--color);
                                  border-radius: 50px;
                                "
                              >
                                <div
                                  style="
                                    width: auto;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    border: 0px;
                                  "
                                >
                                  <div
                                    @click.stop="
                                      openBoxChangeQuantityForItemWithoutModifier(
                                        categoryIndexSelect,
                                        index2,
                                        null,
                                        null
                                      )
                                    "
                                    style="
                                      border: 0px;
                                      width: 30px;
                                      height: 30px;
                                      align-items: center;
                                      justify-content: center;
                                      display: flex;
                                    "
                                  >
                                    <span
                                      style="
                                        color: var(--color);
                                        font-size: 13.1px;
                                      "
                                      >{{
                                        getTotalQuantityById(item.id) ||
                                        categorySelect.quantity
                                      }}</span
                                    >
                                  </div>
                                </div>
                              </div>
                              <div
                                v-else-if="
                                  checkAvailableItem(item) &&
                                  getTotalQuantityById(item.id) == 0 &&
                                  item.isHaveModifierOptionGroups == 0 &&
                                  item.openingEditQuantity
                                "
                                style="
                                  border: 0px;
                                  width: auto;
                                  height: 30px;
                                  border: 1px solid var(--color);
                                  border-radius: 50px;
                                "
                              >
                                <div
                                  style="
                                    width: auto;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    border: 0px;
                                  "
                                >
                                  <div
                                    @click.stop="
                                      handleQuantityForMainItem(
                                        item,
                                        1,
                                        categorySelect.id,
                                        categoryIndexSelect,
                                        index2
                                      )
                                    "
                                    style="
                                      border: 0px;
                                      width: 30px;
                                      height: 30px;
                                      align-items: center;
                                      justify-content: center;
                                      display: flex;
                                      margin-right: 5px;
                                    "
                                  >
                                    <span
                                      style="
                                        color: var(--color);
                                        font-size: 23px;
                                        margin-bottom: 2.5px;
                                      "
                                      >-</span
                                    >
                                  </div>
                                  <div
                                    style="
                                      border: 0px;
                                      min-width: 20px;
                                      height: 30px;
                                      align-items: center;
                                      justify-content: center;
                                      display: flex;
                                      margin-right: 5px;
                                    "
                                  >
                                    <span
                                      style="
                                        color: var(--color);
                                        font-size: 13.1px;
                                      "
                                      >{{ getTotalQuantityById(item.id) }}</span
                                    >
                                  </div>
                                  <div
                                    @click.stop="
                                      handleQuantityForMainItem(
                                        item,
                                        2,
                                        categorySelect.id,
                                        categoryIndexSelect,
                                        index2
                                      )
                                    "
                                    style="
                                      border: 0px;
                                      width: 30px;
                                      height: 30px;
                                      align-items: center;
                                      justify-content: center;
                                      display: flex;
                                    "
                                  >
                                    <span
                                      style="
                                        color: var(--color);
                                        font-size: 23px;
                                        margin-bottom: 2.5px;
                                      "
                                      >+</span
                                    >
                                  </div>
                                </div>
                              </div>
                              <div
                                v-else-if="
                                  checkAvailableItem(item) &&
                                  (getTotalQuantityById(item.id) ||
                                    categorySelect.quantity) > 0 &&
                                  item.isHaveModifierOptionGroups == 0 &&
                                  item.openingEditQuantity
                                "
                                style="
                                  border: 0px;
                                  width: auto;
                                  height: 30px;
                                  border: 1px solid var(--color);
                                  border-radius: 50px;
                                "
                              >
                                <div
                                  style="
                                    width: auto;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    border: 0px;
                                  "
                                >
                                  <div
                                    @click.stop="
                                      handleQuantityForMainItem(
                                        item,
                                        1,
                                        categorySelect.id,
                                        categoryIndexSelect,
                                        index2
                                      )
                                    "
                                    style="
                                      border: 0px;
                                      width: 30px;
                                      height: 30px;
                                      align-items: center;
                                      justify-content: center;
                                      display: flex;
                                      margin-right: 5px;
                                    "
                                  >
                                    <span
                                      style="
                                        color: var(--color);
                                        font-size: 23px;
                                        margin-bottom: 2.5px;
                                      "
                                      >-</span
                                    >
                                  </div>
                                  <div
                                    style="
                                      border: 0px;
                                      min-width: 20px;
                                      height: 30px;
                                      align-items: center;
                                      justify-content: center;
                                      display: flex;
                                      margin-right: 5px;
                                    "
                                  >
                                    <span
                                      style="
                                        color: var(--color);
                                        font-size: 13.1px;
                                      "
                                      >{{
                                        getTotalQuantityById(item.id) ||
                                        categorySelect.quantity
                                      }}</span
                                    >
                                  </div>
                                  <div
                                    @click.stop="
                                      handleQuantityForMainItem(
                                        item,
                                        2,
                                        categorySelect.id,
                                        categoryIndexSelect,
                                        index2
                                      )
                                    "
                                    style="
                                      border: 0px;
                                      width: 30px;
                                      height: 30px;
                                      align-items: center;
                                      justify-content: center;
                                      display: flex;
                                    "
                                  >
                                    <span
                                      style="
                                        color: var(--color);
                                        font-size: 23px;
                                        margin-bottom: 2.5px;
                                      "
                                      >+</span
                                    >
                                  </div>
                                </div>
                              </div>
                              <div
                                v-else-if="
                                  checkAvailableItem(item) &&
                                  getTotalQuantityById(item.id) > 0 &&
                                  item.isHaveModifierOptionGroups > 0
                                "
                                @click="
                                  gotoOrderDetail(
                                    categorySelect.id,
                                    item.id,
                                    item.stock_balance,
                                    item.is_not_available,
                                    item.stock_enable_balance,
                                    item
                                  )
                                "
                                style="
                                  width: 30px;
                                  height: 30px;
                                  border: 1px solid var(--color);
                                  border-radius: 30px;
                                  display: flex;
                                  align-items: center;
                                  justify-content: center;
                                "
                              >
                                <span
                                  style="color: var(--color); font-size: 13.1px"
                                  >{{
                                    getTotalQuantityById(item.id) > 99
                                      ? "99+"
                                      : getTotalQuantityById(item.id)
                                  }}</span
                                >
                              </div>
                              <div
                                v-else-if="
                                  checkAvailableItem(item) &&
                                  getTotalQuantityById(item.id) == 0 &&
                                  item.isHaveModifierOptionGroups == 0
                                "
                                @click.stop="
                                  openBoxChangeQuantityForItemWithoutModifier(
                                    categoryIndexSelect,
                                    index2,
                                    item,
                                    categorySelect.id
                                  )
                                "
                                style="
                                  width: 30px;
                                  height: 30px;
                                  background-color: transparent;
                                  border: 1px solid var(--color) !important;
                                  border-radius: 30px;
                                  align-items: center;
                                  justify-content: center;
                                  display: flex;
                                "
                              >
                                <span
                                  style="
                                    color: var(--color);
                                    font-size: 23px;
                                    margin-bottom: 2.5px;
                                  "
                                  >+<a
                                    href="http://"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  ></a
                                ></span>
                              </div>
                              <div
                                v-else-if="
                                  checkAvailableItem(item) &&
                                  getTotalQuantityById(item.id) == 0 &&
                                  item.isHaveModifierOptionGroups > 0
                                "
                                @click="
                                  gotoOrderDetail(
                                    categorySelect.id,
                                    item.id,
                                    item.stock_balance,
                                    item.is_not_available,
                                    item.stock_enable_balance,
                                    item
                                  )
                                "
                                style="
                                  width: 30px;
                                  height: 30px;
                                  background-color: transparent;
                                  border: 1px solid var(--color) !important;
                                  border-radius: 30px;
                                  align-items: center;
                                  justify-content: center;
                                  display: flex;
                                "
                              >
                                <span
                                  style="
                                    color: var(--color);
                                    font-size: 23px;
                                    margin-bottom: 2.5px;
                                  "
                                  >+</span
                                >
                              </div>
                            </div>
                          </div>
                          <div
                            v-if="item.none_member_price"
                            class="price-defalut active"
                          >
                            {{ formatPrice(item.price_default) }}
                          </div>
                          <div v-else>
                            <div
                              class="price-defalut"
                              :class="{ active: !isLogined }"
                              :style="{
                                'text-decoration': isLogined
                                  ? 'line-through'
                                  : 'none',
                              }"
                            >
                              {{ formatPrice(item.price_default) }}
                            </div>
                            <div
                              class="member-price"
                              :class="{ active: isLogined }"
                            >
                              <span
                                v-if="isLogined"
                                class="font-weight-normal fs-11"
                              >
                                Member price
                                <span class="price">{{
                                  formatPrice(item.member_price)
                                }}</span>
                              </span>
                              <span
                                v-else
                                class="font-weight-normal font-italic fs-11"
                                >Member price available</span
                              >
                            </div>
                          </div>
                        </div>
                        <div
                          v-if="!checkAvailableItem(item)"
                          style="color: #eb2f06; font-size: 14px"
                        >
                          NOT AVAILABLE
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="categorySelect.groups && categorySelect.groups.length">
              <div
                class="item-list"
                v-for="(group, index3) in categorySelect.groups"
                :key="index3"
              >
                <div>
                  <div
                    class="tag-name-group"
                    :class="'tag-name-group-' + group.id"
                  >
                    {{ group.name }}
                  </div>
                  <div class="grid border-0">
                    <div class="row ms-0 me-0 row-flex">
                      <div
                        class="col-6"
                        :class="{
                          'ps-0': index5 % 2 == 0,
                          'pe-0': index5 % 2 == 1,
                        }"
                        v-for="(item, index5) in group.items"
                        :key="index5"
                        @click="
                          gotoOrderDetail(
                            categorySelect.id,
                            item.id,
                            item.stock_balance,
                            item.is_not_available,
                            item.stock_enable_balance,
                            item
                          )
                        "
                      >
                        <div
                          class="col-12 text-center item-info h-100"
                          :class="[
                            'item-img-' +
                              categorySelect.id +
                              '-' +
                              group.id +
                              '-' +
                              item.id,
                            {
                              'item-not-available': !checkAvailableItem(item),
                            },
                          ]"
                          style="position: relative"
                        >
                          <img
                            :src="item.image"
                            alt="error"
                            style="width: 100%"
                          />
                          <div>
                            <div
                              class="item-name"
                              style="
                                line-height: normal;
                                font-weight: bold;
                                font-size: 15px;
                              "
                            >
                              {{ item.item_name }}
                            </div>
                            <div class="category-description">
                              {{ item.description }}
                            </div>
                            <!-- <div
                            style="
                              display: flex;
                              gap: 3px;
                              margin-top: 5px;
                              margin-bottom: 5px;
                              align-items: center;
                              justify-content: center;
                            "
                          >
                            <div
                              class="txt_popular_grid"
                              v-if="checkAndReturnPopular(item.tags)"
                            >
                              {{ checkAndReturnPopular(item.tags) }}
                            </div>
                            <div
                              class="txt_popular_grid"
                              v-if="checkAndReturnSignature(item.tags)"
                            >
                              {{ checkAndReturnSignature(item.tags) }}
                            </div>
                          </div> -->
                            <div class="position-relative mt-3">
                              <div class="change-item-qty add-more-item">
                                <div class="d-flex justify-content-center">
                                  <div
                                    v-if="
                                      checkAvailableItem(item) &&
                                      (getTotalQuantityById(item.id) ||
                                        categorySelect.quantity) > 0 &&
                                      item.isHaveModifierOptionGroups == 0 &&
                                      !item.openingEditQuantity
                                    "
                                    style="
                                      border: 0px;
                                      width: 30px;
                                      height: 30px;
                                      border: 1px solid var(--color);
                                      border-radius: 50px;
                                    "
                                  >
                                    <div
                                      style="
                                        width: auto;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        border: 0px;
                                      "
                                    >
                                      <div
                                        @click.stop="
                                          groupOpenBoxChangeQuantityForItemWithoutModifier(
                                            index3,
                                            index5,
                                            index,
                                            null,
                                            null
                                          )
                                        "
                                        style="
                                          border: 0px;
                                          width: 30px;
                                          height: 30px;
                                          align-items: center;
                                          justify-content: center;
                                          display: flex;
                                        "
                                      >
                                        <span
                                          style="
                                            color: var(--color);
                                            font-size: 13.1px;
                                          "
                                          >{{
                                            getTotalQuantityById(item.id) ||
                                            categorySelect.quantity
                                          }}</span
                                        >
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    v-else-if="
                                      checkAvailableItem(item) &&
                                      getTotalQuantityById(item.id) == 0 &&
                                      item.isHaveModifierOptionGroups == 0 &&
                                      item.openingEditQuantity
                                    "
                                    style="
                                      border: 0px;
                                      width: auto;
                                      height: 30px;
                                      border: 1px solid var(--color);
                                      border-radius: 50px;
                                    "
                                  >
                                    <div
                                      style="
                                        width: auto;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        border: 0px;
                                      "
                                    >
                                      <div
                                        @click.stop="
                                          groupHandleQuantityForMainItem(
                                            item,
                                            1,
                                            group.id,
                                            index3,
                                            index5,
                                            index
                                          )
                                        "
                                        style="
                                          border: 0px;
                                          width: 30px;
                                          height: 30px;
                                          align-items: center;
                                          justify-content: center;
                                          display: flex;
                                          margin-right: 5px;
                                        "
                                      >
                                        <span
                                          style="
                                            color: var(--color);
                                            font-size: 23px;
                                            margin-bottom: 2.5px;
                                          "
                                          >-</span
                                        >
                                      </div>
                                      <div
                                        style="
                                          border: 0px;
                                          min-width: 20px;
                                          height: 30px;
                                          align-items: center;
                                          justify-content: center;
                                          display: flex;
                                          margin-right: 5px;
                                        "
                                      >
                                        <span
                                          style="
                                            color: var(--color);
                                            font-size: 13.1px;
                                          "
                                          >{{
                                            getTotalQuantityById(item.id)
                                          }}</span
                                        >
                                      </div>
                                      <div
                                        @click.stop="
                                          groupHandleQuantityForMainItem(
                                            item,
                                            2,
                                            group.id,
                                            index3,
                                            index5,
                                            index
                                          )
                                        "
                                        style="
                                          border: 0px;
                                          width: 30px;
                                          height: 30px;
                                          align-items: center;
                                          justify-content: center;
                                          display: flex;
                                        "
                                      >
                                        <span
                                          style="
                                            color: var(--color);
                                            font-size: 23px;
                                            margin-bottom: 2.5px;
                                          "
                                          >+</span
                                        >
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    v-else-if="
                                      checkAvailableItem(item) &&
                                      (getTotalQuantityById(item.id) ||
                                        categorySelect.quantity) > 0 &&
                                      item.isHaveModifierOptionGroups == 0 &&
                                      item.openingEditQuantity
                                    "
                                    style="
                                      border: 0px;
                                      width: auto;
                                      height: 30px;
                                      border: 1px solid var(--color);
                                      border-radius: 50px;
                                    "
                                  >
                                    <div
                                      style="
                                        width: auto;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        border: 0px;
                                      "
                                    >
                                      <div
                                        @click.stop="
                                          groupHandleQuantityForMainItem(
                                            item,
                                            1,
                                            group.id,
                                            index3,
                                            index5,
                                            index
                                          )
                                        "
                                        style="
                                          border: 0px;
                                          width: 30px;
                                          height: 30px;
                                          align-items: center;
                                          justify-content: center;
                                          display: flex;
                                          margin-right: 5px;
                                        "
                                      >
                                        <span
                                          style="
                                            color: var(--color);
                                            font-size: 23px;
                                            margin-bottom: 2.5px;
                                          "
                                          >-</span
                                        >
                                      </div>
                                      <div
                                        style="
                                          border: 0px;
                                          min-width: 20px;
                                          height: 30px;
                                          align-items: center;
                                          justify-content: center;
                                          display: flex;
                                          margin-right: 5px;
                                        "
                                      >
                                        <span
                                          style="
                                            color: var(--color);
                                            font-size: 13.1px;
                                          "
                                          >{{
                                            getTotalQuantityById(item.id) ||
                                            categorySelect.quantity
                                          }}</span
                                        >
                                      </div>
                                      <div
                                        @click.stop="
                                          groupHandleQuantityForMainItem(
                                            item,
                                            2,
                                            group.id,
                                            index3,
                                            index5,
                                            index
                                          )
                                        "
                                        style="
                                          border: 0px;
                                          width: 30px;
                                          height: 30px;
                                          align-items: center;
                                          justify-content: center;
                                          display: flex;
                                        "
                                      >
                                        <span
                                          style="
                                            color: var(--color);
                                            font-size: 23px;
                                            margin-bottom: 2.5px;
                                          "
                                          >+</span
                                        >
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    v-else-if="
                                      checkAvailableItem(item) &&
                                      getTotalQuantityById(item.id) > 0 &&
                                      item.isHaveModifierOptionGroups > 0
                                    "
                                    @click="
                                      gotoOrderDetail(
                                        categorySelect.id,
                                        item.id,
                                        item.stock_balance,
                                        item.is_not_available,
                                        item.stock_enable_balance,
                                        item
                                      )
                                    "
                                    style="
                                      width: 30px;
                                      height: 30px;
                                      border: 1px solid var(--color);
                                      border-radius: 30px;
                                      display: flex;
                                      align-items: center;
                                      justify-content: center;
                                    "
                                  >
                                    <span
                                      style="
                                        color: var(--color);
                                        font-size: 13.1px;
                                      "
                                      >{{
                                        getTotalQuantityById(item.id) > 99
                                          ? "99+"
                                          : getTotalQuantityById(item.id)
                                      }}</span
                                    >
                                  </div>
                                  <div
                                    v-else-if="
                                      checkAvailableItem(item) &&
                                      getTotalQuantityById(item.id) == 0 &&
                                      item.isHaveModifierOptionGroups == 0
                                    "
                                    @click.stop="
                                      groupOpenBoxChangeQuantityForItemWithoutModifier(
                                        index3,
                                        index5,
                                        index,
                                        item,
                                        group.id
                                      )
                                    "
                                    style="
                                      width: 30px;
                                      height: 30px;
                                      background-color: transparent;
                                      border: 1px solid var(--color) !important;
                                      border-radius: 30px;
                                      align-items: center;
                                      justify-content: center;
                                      display: flex;
                                    "
                                  >
                                    <span
                                      style="
                                        color: var(--color);
                                        font-size: 23px;
                                        margin-bottom: 2.5px;
                                      "
                                      >+</span
                                    >
                                  </div>
                                  <div
                                    v-else-if="
                                      checkAvailableItem(item) &&
                                      getTotalQuantityById(item.id) == 0 &&
                                      item.isHaveModifierOptionGroups > 0
                                    "
                                    @click="
                                      gotoOrderDetail(
                                        categorySelect.id,
                                        item.id,
                                        item.stock_balance,
                                        item.is_not_available,
                                        item.stock_enable_balance,
                                        item
                                      )
                                    "
                                    style="
                                      width: 30px;
                                      height: 30px;
                                      background-color: transparent;
                                      border: 1px solid var(--color) !important;
                                      border-radius: 30px;
                                      align-items: center;
                                      justify-content: center;
                                      display: flex;
                                    "
                                  >
                                    <span
                                      style="
                                        color: var(--color);
                                        font-size: 23px;
                                        margin-bottom: 2.5px;
                                      "
                                      >+</span
                                    >
                                  </div>
                                </div>
                              </div>
                              <div
                                v-if="item.none_member_price"
                                class="price-defalut active"
                              >
                                {{ formatPrice(item.price_default) }}
                              </div>
                              <div v-else>
                                <div
                                  class="price-defalut"
                                  :class="{ active: !isLogined }"
                                  :style="{
                                    'text-decoration': isLogined
                                      ? 'line-through'
                                      : 'none',
                                  }"
                                >
                                  {{ formatPrice(item.price_default) }}
                                </div>
                                <div
                                  class="member-price"
                                  :class="{ active: isLogined }"
                                >
                                  <span
                                    v-if="isLogined"
                                    class="font-weight-normal fs-11"
                                  >
                                    Member price
                                    <span class="price">{{
                                      formatPrice(item.member_price)
                                    }}</span>
                                  </span>
                                  <span
                                    v-else
                                    class="font-weight-normal font-italic fs-11"
                                    >Member price available</span
                                  >
                                </div>
                              </div>
                            </div>
                            <div
                              v-if="!checkAvailableItem(item)"
                              style="color: #eb2f06; font-size: 14px"
                            >
                              NOT AVAILABLE
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="group.sub_groups && group.sub_groups.length">
                  <div
                    class="item-list"
                    v-for="(subGroup, index6) in group.sub_groups"
                    :key="index6"
                  >
                    <div>
                      <div class="tag-name-sub-group">
                        {{ subGroup.name }}
                      </div>
                      <div class="grid border-0">
                        <div class="row ms-0 me-0 row-flex">
                          <div
                            class="col-6"
                            :class="{
                              'ps-0': index8 % 2 == 0,
                              'pe-0': index8 % 2 == 1,
                            }"
                            v-for="(item, index8) in subGroup.sub_group_items"
                            :key="index8"
                            @click="
                              gotoOrderDetail(
                                categorySelect.id,
                                item.id,
                                item.stock_balance,
                                item.is_not_available,
                                item.stock_enable_balance,
                                item
                              )
                            "
                          >
                            <div
                              class="col-12 text-center item-info h-100"
                              :class="[
                                'item-img-' +
                                  categorySelect.id +
                                  '-' +
                                  group.id +
                                  '-' +
                                  subGroup.id +
                                  '-' +
                                  item.id,
                                {
                                  'item-not-available': !checkAvailableItem(
                                    item
                                  ),
                                },
                              ]"
                              style="position: relative"
                            >
                              <img
                                :src="item.image"
                                alt="error"
                                style="width: 100%"
                              />
                              <div>
                                <div
                                  class="item-name"
                                  style="
                                    line-height: normal;
                                    font-weight: bold;
                                    font-size: 15px;
                                  "
                                >
                                  {{ item.item_name }}
                                </div>
                                <div class="category-description">
                                  {{ item.description }}
                                </div>
                                <!-- <div
                                style="
                                  display: flex;
                                  gap: 3px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                  align-items: center;
                                  justify-content: center;
                                "
                              >
                                <div
                                  class="txt_popular_grid"
                                  v-if="checkAndReturnPopular(item.tags)"
                                >
                                  {{ checkAndReturnPopular(item.tags) }}
                                </div>
                                <div
                                  class="txt_popular_grid"
                                  v-if="checkAndReturnSignature(item.tags)"
                                >
                                  {{ checkAndReturnSignature(item.tags) }}
                                </div>
                              </div> -->
                                <div class="position-relative mt-3">
                                  <div class="change-item-qty add-more-item">
                                    <div class="d-flex justify-content-center">
                                      <div
                                        v-if="
                                          checkAvailableItem(item) &&
                                          (getTotalQuantityById(item.id) ||
                                            categorySelect.quantity) > 0 &&
                                          item.isHaveModifierOptionGroups ==
                                            0 &&
                                          !item.openingEditQuantity
                                        "
                                        style="
                                          border: 0px;
                                          width: 30px;
                                          height: 30px;
                                          border: 1px solid var(--color);
                                          border-radius: 50px;
                                        "
                                      >
                                        <div
                                          style="
                                            width: auto;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            border: 0px;
                                          "
                                        >
                                          <div
                                            @click="
                                              subGroupOpenBoxChangeQuantityForItemWithoutModifier(
                                                index3,
                                                index8,
                                                index,
                                                index6,
                                                null,
                                                null
                                              )
                                            "
                                            style="
                                              border: 0px;
                                              width: 30px;
                                              height: 30px;
                                              align-items: center;
                                              justify-content: center;
                                              display: flex;
                                            "
                                          >
                                            <span
                                              style="
                                                color: var(--color);
                                                font-size: 13.1px;
                                              "
                                              >{{
                                                getTotalQuantityById(item.id) ||
                                                categorySelect.quantity
                                              }}</span
                                            >
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        v-else-if="
                                          checkAvailableItem(item) &&
                                          getTotalQuantityById(item.id) == 0 &&
                                          item.isHaveModifierOptionGroups ==
                                            0 &&
                                          item.openingEditQuantity
                                        "
                                        style="
                                          border: 0px;
                                          width: auto;
                                          height: 30px;
                                          border: 1px solid var(--color);
                                          border-radius: 50px;
                                        "
                                      >
                                        <div
                                          style="
                                            width: auto;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            border: 0px;
                                          "
                                        >
                                          <div
                                            @click.stop="
                                              subGroupHandleQuantityForMainItem(
                                                item,
                                                1,
                                                subGroup.id,
                                                index3,
                                                index8,
                                                index,
                                                index6
                                              )
                                            "
                                            style="
                                              border: 0px;
                                              width: 30px;
                                              height: 30px;
                                              align-items: center;
                                              justify-content: center;
                                              display: flex;
                                              margin-right: 5px;
                                            "
                                          >
                                            <span
                                              style="
                                                color: var(--color);
                                                font-size: 23px;
                                                margin-bottom: 2.5px;
                                              "
                                              >-</span
                                            >
                                          </div>
                                          <div
                                            style="
                                              border: 0px;
                                              min-width: 20px;
                                              height: 30px;
                                              align-items: center;
                                              justify-content: center;
                                              display: flex;
                                              margin-right: 5px;
                                            "
                                          >
                                            <span
                                              style="
                                                color: var(--color);
                                                font-size: 13.1px;
                                              "
                                              >{{
                                                getTotalQuantityById(item.id)
                                              }}</span
                                            >
                                          </div>
                                          <div
                                            @click.stop="
                                              subGroupHandleQuantityForMainItem(
                                                item,
                                                2,
                                                subGroup.id,
                                                index3,
                                                index8,
                                                index,
                                                index6
                                              )
                                            "
                                            style="
                                              border: 0px;
                                              width: 30px;
                                              height: 30px;
                                              align-items: center;
                                              justify-content: center;
                                              display: flex;
                                            "
                                          >
                                            <span
                                              style="
                                                color: var(--color);
                                                font-size: 23px;
                                                margin-bottom: 2.5px;
                                              "
                                              >+</span
                                            >
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        v-else-if="
                                          checkAvailableItem(item) &&
                                          (getTotalQuantityById(item.id) ||
                                            categorySelect.quantity) > 0 &&
                                          item.isHaveModifierOptionGroups ==
                                            0 &&
                                          item.openingEditQuantity
                                        "
                                        style="
                                          border: 0px;
                                          width: auto;
                                          height: 30px;
                                          border: 1px solid var(--color);
                                          border-radius: 50px;
                                        "
                                      >
                                        <div
                                          style="
                                            width: auto;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            border: 0px;
                                          "
                                        >
                                          <div
                                            @click.stop="
                                              subGroupHandleQuantityForMainItem(
                                                item,
                                                1,
                                                subGroup.id,
                                                index3,
                                                index8,
                                                index,
                                                index6
                                              )
                                            "
                                            style="
                                              border: 0px;
                                              width: 30px;
                                              height: 30px;
                                              align-items: center;
                                              justify-content: center;
                                              display: flex;
                                              margin-right: 5px;
                                            "
                                          >
                                            <span
                                              style="
                                                color: var(--color);
                                                font-size: 23px;
                                                margin-bottom: 2.5px;
                                              "
                                              >-</span
                                            >
                                          </div>
                                          <div
                                            style="
                                              border: 0px;
                                              min-width: 20px;
                                              height: 30px;
                                              align-items: center;
                                              justify-content: center;
                                              display: flex;
                                              margin-right: 5px;
                                            "
                                          >
                                            <span
                                              style="
                                                color: var(--color);
                                                font-size: 13.1px;
                                              "
                                              >{{
                                                getTotalQuantityById(item.id) ||
                                                categorySelect.quantity
                                              }}</span
                                            >
                                          </div>
                                          <div
                                            @click.stop="
                                              subGroupHandleQuantityForMainItem(
                                                item,
                                                2,
                                                subGroup.id,
                                                index3,
                                                index8,
                                                index,
                                                index6
                                              )
                                            "
                                            style="
                                              border: 0px;
                                              width: 30px;
                                              height: 30px;
                                              align-items: center;
                                              justify-content: center;
                                              display: flex;
                                            "
                                          >
                                            <span
                                              style="
                                                color: var(--color);
                                                font-size: 23px;
                                                margin-bottom: 2.5px;
                                              "
                                              >+</span
                                            >
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        v-else-if="
                                          checkAvailableItem(item) &&
                                          getTotalQuantityById(item.id) > 0 &&
                                          item.isHaveModifierOptionGroups > 0
                                        "
                                        @click="
                                          gotoOrderDetail(
                                            categorySelect.id,
                                            item.id,
                                            item.stock_balance,
                                            item.is_not_available,
                                            item.stock_enable_balance,
                                            item
                                          )
                                        "
                                        style="
                                          width: 30px;
                                          height: 30px;
                                          border: 1px solid var(--color);
                                          border-radius: 30px;
                                          display: flex;
                                          align-items: center;
                                          justify-content: center;
                                        "
                                      >
                                        <span
                                          style="
                                            color: var(--color);
                                            font-size: 13.1px;
                                          "
                                          >{{
                                            getTotalQuantityById(item.id) > 99
                                              ? "99+"
                                              : getTotalQuantityById(item.id)
                                          }}</span
                                        >
                                      </div>
                                      <div
                                        v-else-if="
                                          checkAvailableItem(item) &&
                                          getTotalQuantityById(item.id) == 0 &&
                                          item.isHaveModifierOptionGroups == 0
                                        "
                                        @click="
                                          subGroupOpenBoxChangeQuantityForItemWithoutModifier(
                                            index3,
                                            index8,
                                            index,
                                            index6,
                                            item,
                                            subGroup.id
                                          )
                                        "
                                        style="
                                          width: 30px;
                                          height: 30px;
                                          background-color: transparent;
                                          border: 1px solid var(--color) !important;
                                          border-radius: 30px;
                                          align-items: center;
                                          justify-content: center;
                                          display: flex;
                                        "
                                      >
                                        <span
                                          style="
                                            color: var(--color);
                                            font-size: 23px;
                                            margin-bottom: 2.5px;
                                          "
                                          >+</span
                                        >
                                      </div>
                                      <div
                                        v-else-if="
                                          checkAvailableItem(item) &&
                                          getTotalQuantityById(item.id) == 0 &&
                                          item.isHaveModifierOptionGroups > 0
                                        "
                                        @click="
                                          gotoOrderDetail(
                                            category.id,
                                            item.id,
                                            item.stock_balance,
                                            item.is_not_available,
                                            item.stock_enable_balance,
                                            item
                                          )
                                        "
                                        style="
                                          width: 30px;
                                          height: 30px;
                                          background-color: transparent;
                                          border: 1px solid var(--color) !important;
                                          border-radius: 30px;
                                          align-items: center;
                                          justify-content: center;
                                          display: flex;
                                        "
                                      >
                                        <span
                                          style="
                                            color: var(--color);
                                            font-size: 23px;
                                            margin-bottom: 2.5px;
                                          "
                                          >+</span
                                        >
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    v-if="item.none_member_price"
                                    class="price-defalut active"
                                  >
                                    {{ formatPrice(item.price_default) }}
                                  </div>
                                  <div v-else>
                                    <div
                                      class="price-defalut"
                                      :class="{ active: !isLogined }"
                                      :style="{
                                        'text-decoration': isLogined
                                          ? 'line-through'
                                          : 'none',
                                      }"
                                    >
                                      {{ formatPrice(item.price_default) }}
                                    </div>
                                    <div
                                      class="member-price"
                                      :class="{ active: isLogined }"
                                    >
                                      <span
                                        v-if="isLogined"
                                        class="font-weight-normal fs-11"
                                      >
                                        Member price
                                        <span class="price">{{
                                          formatPrice(item.member_price)
                                        }}</span>
                                      </span>
                                      <span
                                        v-else
                                        class="font-weight-normal font-italic fs-11"
                                        >Member price available</span
                                      >
                                    </div>
                                  </div>
                                </div>
                                <div
                                  v-if="!checkAvailableItem(item)"
                                  style="color: white; font-size: 14px"
                                >
                                  NOT AVAILABLE
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
      <div
        class="position-fixed top-1 end-1 message-success"
        style="z-index: 99999 !important"
      >
        <material-snackbar
          v-if="snackbar === 'danger' || snackbar === 'success'"
          title=""
          date=""
          :description="message"
          :icon="{ component: 'campaign', color: 'white' }"
          :color="snackbar"
          :close-handler="closeSnackbar"
        />
      </div>
    </div>
    <MemorizeYourBirthday />
    <material-loading :active="loaderActive" />
    <material-loading-without-animation
      :active="loaderActiveWithoutAnimation"
    />
    <ModalItemDetail
      :categoryId="categoryIdSelect"
      :itemId="itemIdSelect"
      :itemDetail="itemDetail"
      :isLogined="isLogined"
      :reloadModal="reloadModal"
      @clear-data-item="clearDataItem"
    />
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from "vue";
import SwiperCore, { Pagination, Autoplay } from "swiper";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import Header from "../kiosk/components/Header.vue";
import OrderService from "../../services/order.service";
import $ from "jquery";
import "bootstrap/dist/js/bootstrap.bundle";
import "bootstrap/js/dist/modal";
import MemorizeYourBirthday from "../../components/MemorizeYourBirthday.vue";
import Footer from "../kiosk/components/OrderFooter.vue";
import MaterialLoading from "@/components/MaterialLoading.vue";
import ModalItemDetail from "../kiosk/components/ModalItemDetail.vue";
import EventBus from "vue3-eventbus";
import MaterialLoadingWithoutAnimation from "@/components/MaterialLoadingWithoutAnimation.vue";
import { TweenMax } from "gsap";
import { localStorageName } from "@/config/utils";
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";

SwiperCore.use([Pagination, Autoplay]);

export default {
  name: "Order",
  components: {
    Header,
    MemorizeYourBirthday,
    Footer,
    MaterialLoading,
    ModalItemDetail,
    MaterialLoadingWithoutAnimation,
    MaterialSnackbar,
  },
  data() {
    return {
      snackbar: null,
      message: "",
      isLoadDone: false,
      loaderActive: false,
      merchantCode: "",
      memberInfo: JSON.parse(
        localStorage.getItem(localStorageName("memberInfo"), "")
      ),
      branchCode: "",
      branch: JSON.parse(
        localStorage.getItem(localStorageName("BRANCH_INFO"), "")
      ),
      pathType: "",
      categories: [],
      categoryIdSelect: this.$route.params.category_id ?? 0,
      categorySelect: {},
      categoryIndexSelect: 1,
      groupSelect: null,
      typeList: 1,
      itemSearch: [],
      itemIdSelect: 0,
      itemDetail: {},
      reloadModal: "reload-item-detail",
      isLogined: false,
      cartName: "ORDER_INFO",
      isLoadingGetItemDetail: false,
      loaderActiveWithoutAnimation: false,
      textLostInternet:
        "Your internet connection has been lost, and items cannot be added to your cart at this time.",
    };
  },
  setup() {
    const isOnline = ref(navigator.onLine);

    const updateOnlineStatus = () => {
      isOnline.value = navigator.onLine;
    };

    onMounted(() => {
      window.addEventListener("online", updateOnlineStatus);
      window.addEventListener("offline", updateOnlineStatus);
    });

    onUnmounted(() => {
      window.removeEventListener("online", updateOnlineStatus);
      window.removeEventListener("offline", updateOnlineStatus);
    });

    return {
      isOnline,
    };
  },
  async created() {
    this.merchantCode = this.$store.state.merchantCode;
    this.branchCode = this.$store.state.branchCode;
    this.cartName = await this.$store.dispatch("cartName");

    if (this.memberInfo) {
      this.isLogined = true;
    }
  },
  async mounted() {
    const memorize = this.$route.params.memorize ?? null;
    if (memorize) {
      $(".bottom_modal_memorize").click();
    }

    if (this.merchantCode && this.branchCode) {
      await this.getListCategory(1, true);
    }

    EventBus.on("AddToCartThenReloadCategorySuccess", (e) => {
      setTimeout(() => {
        if (e.time == this.reloadModal) {
          this.getListCategory(2, false);
        }
      }, 300);
    });

    window.onpopstate = (e) => {
      if (this.itemIdSelect) {
        e.preventDefault();
        history.go(1);
      }
    };
  },
  computed: {
    styleObject() {
      return {
        "--color": this.$store.state.color,
      };
    },
  },
  methods: {
    formatPrice(price) {
      return "$ " + parseFloat(price).toFixed(2);
    },

    clearDataItem() {
      this.itemIdSelect = 0;
      this.itemDetail = {};
    },

    gotoOrderDetail(
      categoryId,
      id,
      stock_balance,
      is_not_available,
      stock_enable_balance,
      item
    ) {
      // console.log(stock_balance, is_not_available, stock_enable_balance, item)
      // if(this.checkAvailableItem(item) && item.isHaveModifierOptionGroups > 0) {
      if (this.checkAvailableItem(item)) {
        this.categoryIdSelect = categoryId;
        this.itemIdSelect = id;

        // this.showLoader();
        let dataForm = {
          menuset_id: this.branch.menuset_id ?? 0,
          category_id: categoryId,
          item_id: id,
          branch_code: this.branchCode,
        };

        OrderService.getItemDetail(dataForm).then(
          (response) => {
            if (response.data.result.isSuccess) {
              this.itemDetail = response.data.data;
              this.reloadModal = Math.random(32).toString();
              $(".btn-show-item-detail-modal").click();
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
            this.isLoadDone = true;
            // this.hideLoader();
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            // this.hideLoader();
          }
        );
      }
    },

    selectCategory(category, index) {
      this.categorySelect = category;
      this.categoryIndexSelect = index;
      this.groupSelect = null;

      if (index > 1) {
        setTimeout(() => {
          const screenHeight = $(".category-list").height();
          $(".category-list").animate(
            {
              scrollTop:
                $("#category-tag-" + category.id).offset().top +
                $(".category-list").scrollTop() -
                screenHeight / 3,
            },
            300
          );
        }, 100);
      } else {
        $(".category-list").scrollLeft(0);
        setTimeout(() => {
          $(".category-list").animate(
            {
              scrollTop: 0,
            },
            300
          );
        }, 100);
      }

      $(".order-info .content").animate(
        {
          scrollTop: 0,
        },
        300
      );
    },

    selectGroup(group) {
      this.groupSelect = group;
      if (this.typeList == 2) {
        $(".group-list > .group-tag").removeClass("active");
        $("#group-tag-" + group.id).addClass("active");
        $(".content-custom").scrollTop(0, 0);
        $(".content-custom").animate(
          {
            scrollTop: $(".tag-name-group-" + group.id).offset().top - 80,
          },
          500
        );
      } else {
        $(".group-list > .group-tag").removeClass("active");
        $("#group-tag-" + group.id).addClass("active");
        $(".content-custom").scrollTop(0, 0);
        setTimeout(() => {
          $(".content-custom").animate(
            {
              scrollTop:
                $(".tag-name-group-" + group.id).offset().top -
                (110 - $(".order-header").height()),
            },
            500
          );
        }, 100);
      }
    },

    async getListCategory(type, isLoadingAnimation) {
      if (isLoadingAnimation) {
        this.showLoader();
      }
      let dataForm = {
        menuset_id: this.branch.menuset_id ?? 0,
        branch_code: this.branch.branch_code ?? "",
      };

      await OrderService.getListCategory(dataForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.isLoadDone = true;
            this.categories = response.data.data.categories;

            if (type == 1) {
              if (this.categories.length) {
                if (this.categoryIdSelect) {
                  this.categories.forEach((category, index) => {
                    if (this.categoryIdSelect == category.id) {
                      this.selectCategory(category, index, true);
                      return;
                    }
                  });
                } else {
                  this.categorySelect = this.categories[0];
                  this.categoryIndexSelect = 0;
                  this.groupSelect = null;

                  setTimeout(() => {
                    $(".tag-name-category-" + this.categorySelect.id).addClass(
                      "active"
                    );
                  }, 100);
                }
              }
            }
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.isLoadDone = true;
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
        }
      );
    },

    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },

    showLoader() {
      this.loaderActive = true;
    },

    hideLoader() {
      this.loaderActive = false;
    },

    checkAndReturnPopular(array) {
      if (array && array.length > 0) {
        if (array.includes("Popular")) {
          return "Popular";
        } else {
          return "";
        }
      }
      return "";
    },

    checkAndReturnSignature(array) {
      if (array && array.length > 0) {
        if (array.includes("Signature")) {
          return "Signature";
        } else {
          return "";
        }
      }
      return "";
    },

    clickLink(link) {
      if (link && link != "") {
        window.open(link);
      }
    },

    checkAvailableItem(item) {
      if (item.stock_enable_balance) {
        // countdown = true
        if (item.stock_balance > 0) {
          return true;
        } else {
          return false;
        }
      } else {
        // countdown = false
        if (item.is_not_available == 0) {
          return true;
        } else {
          return false;
        }
      }
    },

    getTotalQuantityById(id) {
      const data = JSON.parse(localStorage.getItem(this.cartName, []));
      let totalQuantity = 0;
      if (data) {
        for (let i = 0; i < data.length; i++) {
          if (data[i].id === id) {
            totalQuantity += data[i].quantity;
          }
        }
      }
      return totalQuantity;
    },

    async handleQuantityForMainItem(
      mainItem,
      type,
      category_id,
      categoryIndex,
      itemIndex
    ) {
      // 1 is - | 2 is +
      let orderInfo =
        JSON.parse(localStorage.getItem(this.cartName, null)) ?? [];
      const indexToAddQuantity = orderInfo.findIndex(
        (item) => item.id === mainItem.id
      );
      if (type == 1) {
        if (this.getTotalQuantityById(mainItem.id) === 1) {
          // If quantity is going to be decremented to 0, show confirm dialog
          if (
            confirm(
              "Are you sure you want to remove this item '" +
                mainItem.item_name +
                "' from your cart?"
            )
          ) {
            this.showLoader();
            this.categories[categoryIndex].items[itemIndex].quantity =
              orderInfo[indexToAddQuantity].quantity - 1;
            orderInfo[indexToAddQuantity].quantity =
              orderInfo[indexToAddQuantity].quantity - 1;
            if (orderInfo[indexToAddQuantity].quantity == 0) {
              orderInfo.splice(indexToAddQuantity, 1);
            }
            localStorage.removeItem(this.cartName);
            localStorage.setItem(this.cartName, JSON.stringify(orderInfo));
            this.isLoadDone = true;
            this.hideLoader();
            EventBus.emit("AddToCartSuccess");
          }
        } else {
          if (orderInfo[indexToAddQuantity]) {
            if (orderInfo[indexToAddQuantity].quantity > 0) {
              this.showLoader();
              this.categories[categoryIndex].items[itemIndex].quantity =
                orderInfo[indexToAddQuantity].quantity - 1;
              orderInfo[indexToAddQuantity].quantity =
                orderInfo[indexToAddQuantity].quantity - 1;
              localStorage.removeItem(this.cartName);
              localStorage.setItem(this.cartName, JSON.stringify(orderInfo));
              this.isLoadDone = true;
              this.hideLoader();
              EventBus.emit("ChangeItemQuantitySuccess");
            }
          }
        }
      } else if (type == 2) {
        if (orderInfo[indexToAddQuantity]) {
          this.addCartAnimation(mainItem.id, itemIndex, category_id);
          this.showLoader();
          this.categories[categoryIndex].items[itemIndex].quantity =
            orderInfo[indexToAddQuantity].quantity + 1;
          orderInfo[indexToAddQuantity].quantity =
            orderInfo[indexToAddQuantity].quantity + 1;
          localStorage.removeItem(this.cartName);
          localStorage.setItem(this.cartName, JSON.stringify(orderInfo));
          this.isLoadDone = true;
          this.hideLoader();
          EventBus.emit("ChangeItemQuantitySuccess");
        } else {
          if (this.isLoadingGetItemDetail == false) {
            if (this.isOnline) {
              this.addCartAnimation(mainItem.id, itemIndex, category_id);
              this.isLoadingGetItemDetail = true;
              this.showLoaderWithoutAnimation();
              let dataForm = {
                menuset_id: this.branch.menuset_id ?? 0,
                category_id: category_id,
                item_id: mainItem.id,
                branch_code: this.branchCode,
              };

              OrderService.getItemDetail(dataForm).then(
                (response) => {
                  if (response.data.result.isSuccess) {
                    this.categories[categoryIndex].items[
                      itemIndex
                    ].quantity = 1;
                    let orderInfo =
                      JSON.parse(localStorage.getItem(this.cartName, null)) ??
                      [];
                    let itemDetail = response.data.data;
                    itemDetail.price_default = parseFloat(
                      itemDetail.price_default
                    );
                    itemDetail.member_price = parseFloat(
                      itemDetail.member_price
                    );

                    orderInfo.push(itemDetail);
                    localStorage.setItem(
                      this.cartName,
                      JSON.stringify(orderInfo)
                    );
                    EventBus.emit("ChangeItemQuantitySuccess");
                  } else {
                    this.snackbar = "danger";
                    this.message = response.data.result.message;
                    this.setTimeOutNotification();
                  }
                  this.isLoadDone = true;
                  this.hideLoaderWithoutAnimation();
                  this.isLoadingGetItemDetail = false;
                },
                (error) => {
                  this.snackbar = "danger";
                  this.message = error;
                  this.hideLoaderWithoutAnimation();
                  this.isLoadingGetItemDetail = false;
                }
              );
            } else {
              alert(this.textLostInternet);
            }
          }
        }
      }
    },

    openBoxChangeQuantityForItemWithoutModifier(
      categoryIndex,
      itemIndex,
      item,
      category_id
    ) {
      for (const category of this.categories) {
        for (const item of category.items) {
          delete item.openingEditQuantity;
        }
      }
      this.categories[categoryIndex].items[
        itemIndex
      ].openingEditQuantity = true;
      if (item) {
        this.handleQuantityForMainItem(
          item,
          2,
          category_id,
          categoryIndex,
          itemIndex
        );
      }
    },

    async groupHandleQuantityForMainItem(
      mainItem,
      type,
      category_id,
      groupIndex,
      itemIndex,
      categoryIndex
    ) {
      // 1 is - | 2 is +
      let orderInfo =
        JSON.parse(localStorage.getItem(this.cartName, null)) ?? [];
      const indexToAddQuantity = orderInfo.findIndex(
        (item) => item.id === mainItem.id
      );
      if (type == 1) {
        if (this.getTotalQuantityById(mainItem.id) === 1) {
          // If quantity is going to be decremented to 0, show confirm dialog
          if (
            confirm(
              "Are you sure you want to remove this item '" +
                mainItem.item_name +
                "' from your cart?"
            )
          ) {
            this.showLoader();
            this.categories[categoryIndex].groups[groupIndex].items[
              itemIndex
            ].quantity = orderInfo[indexToAddQuantity].quantity - 1;
            orderInfo[indexToAddQuantity].quantity =
              orderInfo[indexToAddQuantity].quantity - 1;
            if (orderInfo[indexToAddQuantity].quantity == 0) {
              orderInfo.splice(indexToAddQuantity, 1);
            }
            localStorage.removeItem(this.cartName);
            localStorage.setItem(this.cartName, JSON.stringify(orderInfo));
            this.isLoadDone = true;
            this.hideLoader();
            EventBus.emit("AddToCartSuccess");
          }
        } else {
          if (orderInfo[indexToAddQuantity]) {
            if (orderInfo[indexToAddQuantity].quantity > 0) {
              this.showLoader();
              this.categories[categoryIndex].groups[groupIndex].items[
                itemIndex
              ].quantity = orderInfo[indexToAddQuantity].quantity - 1;
              orderInfo[indexToAddQuantity].quantity =
                orderInfo[indexToAddQuantity].quantity - 1;
              localStorage.removeItem(this.cartName);
              localStorage.setItem(this.cartName, JSON.stringify(orderInfo));
              this.isLoadDone = true;
              this.hideLoader();
              EventBus.emit("ChangeItemQuantitySuccess");
            }
          }
        }
      } else if (type == 2) {
        if (orderInfo[indexToAddQuantity]) {
          this.addCartAnimation(
            mainItem.id,
            itemIndex,
            this.categories[categoryIndex].id,
            this.categories[categoryIndex].groups[groupIndex].id
          );
          this.showLoader();
          this.categories[categoryIndex].groups[groupIndex].items[
            itemIndex
          ].quantity = orderInfo[indexToAddQuantity].quantity + 1;
          orderInfo[indexToAddQuantity].quantity =
            orderInfo[indexToAddQuantity].quantity + 1;
          localStorage.removeItem(this.cartName);
          localStorage.setItem(this.cartName, JSON.stringify(orderInfo));
          this.isLoadDone = true;
          this.hideLoader();
          EventBus.emit("ChangeItemQuantitySuccess");
        } else {
          if (this.isLoadingGetItemDetail == false) {
            if (this.isOnline) {
              this.addCartAnimation(
                mainItem.id,
                itemIndex,
                this.categories[categoryIndex].id,
                this.categories[categoryIndex].groups[groupIndex].id
              );
              this.isLoadingGetItemDetail = true;
              this.showLoaderWithoutAnimation();
              let dataForm = {
                menuset_id: this.branch.menuset_id ?? 0,
                category_id: category_id,
                item_id: mainItem.id,
                branch_code: this.branchCode,
              };

              OrderService.getItemDetail(dataForm).then(
                (response) => {
                  if (response.data.result.isSuccess) {
                    this.categories[categoryIndex].groups[groupIndex].items[
                      itemIndex
                    ].quantity = 1;
                    let orderInfo =
                      JSON.parse(localStorage.getItem(this.cartName, null)) ??
                      [];
                    let itemDetail = response.data.data;
                    itemDetail.price_default = parseFloat(
                      itemDetail.price_default
                    );
                    itemDetail.member_price = parseFloat(
                      itemDetail.member_price
                    );

                    orderInfo.push(itemDetail);
                    localStorage.setItem(
                      this.cartName,
                      JSON.stringify(orderInfo)
                    );
                    EventBus.emit("ChangeItemQuantitySuccess");
                  } else {
                    this.snackbar = "danger";
                    this.message = response.data.result.message;
                    this.setTimeOutNotification();
                  }
                  this.isLoadDone = true;
                  this.hideLoaderWithoutAnimation();
                  this.isLoadingGetItemDetail = false;
                },
                (error) => {
                  this.snackbar = "danger";
                  this.message = error;
                  this.hideLoaderWithoutAnimation();
                  this.isLoadingGetItemDetail = false;
                }
              );
            } else {
              alert(this.textLostInternet);
            }
          }
        }
      }
    },

    groupOpenBoxChangeQuantityForItemWithoutModifier(
      groupIndex,
      itemIndex,
      categoryIndex,
      item,
      group_id
    ) {
      for (const category of this.categories) {
        for (const group of category.groups) {
          for (const item of group.items) {
            delete item.openingEditQuantity;
          }
        }
      }
      this.categories[categoryIndex].groups[groupIndex].items[
        itemIndex
      ].openingEditQuantity = true;
      if (item) {
        this.groupHandleQuantityForMainItem(
          item,
          2,
          group_id,
          groupIndex,
          itemIndex,
          categoryIndex
        );
      }
    },

    async subGroupHandleQuantityForMainItem(
      mainItem,
      type,
      category_id,
      groupIndex,
      itemIndex,
      categoryIndex,
      subGroupIndex
    ) {
      // 1 is - | 2 is +
      let orderInfo =
        JSON.parse(localStorage.getItem(this.cartName, null)) ?? [];
      const indexToAddQuantity = orderInfo.findIndex(
        (item) => item.id === mainItem.id
      );
      if (type == 1) {
        if (this.getTotalQuantityById(mainItem.id) === 1) {
          // If quantity is going to be decremented to 0, show confirm dialog
          if (
            confirm(
              "Are you sure you want to remove this item '" +
                mainItem.item_name +
                "' from your cart?"
            )
          ) {
            this.showLoader();
            this.categories[categoryIndex].groups[groupIndex].sub_groups[
              subGroupIndex
            ].sub_group_items[itemIndex].quantity =
              orderInfo[indexToAddQuantity].quantity - 1;
            orderInfo[indexToAddQuantity].quantity =
              orderInfo[indexToAddQuantity].quantity - 1;
            if (orderInfo[indexToAddQuantity].quantity == 0) {
              orderInfo.splice(indexToAddQuantity, 1);
            }
            localStorage.removeItem(this.cartName);
            localStorage.setItem(this.cartName, JSON.stringify(orderInfo));
            this.isLoadDone = true;
            this.hideLoader();
            EventBus.emit("AddToCartSuccess");
          }
        } else {
          if (orderInfo[indexToAddQuantity]) {
            if (orderInfo[indexToAddQuantity].quantity > 0) {
              this.showLoader();
              this.categories[categoryIndex].groups[groupIndex].sub_groups[
                subGroupIndex
              ].sub_group_items[itemIndex].quantity =
                orderInfo[indexToAddQuantity].quantity - 1;
              orderInfo[indexToAddQuantity].quantity =
                orderInfo[indexToAddQuantity].quantity - 1;
              localStorage.removeItem(this.cartName);
              localStorage.setItem(this.cartName, JSON.stringify(orderInfo));
              this.isLoadDone = true;
              this.hideLoader();
              EventBus.emit("ChangeItemQuantitySuccess");
            }
          }
        }
      } else if (type == 2) {
        if (orderInfo[indexToAddQuantity]) {
          this.addCartAnimation(
            mainItem.id,
            itemIndex,
            this.categories[categoryIndex].id,
            this.categories[categoryIndex].groups[groupIndex].id,
            this.categories[categoryIndex].groups[groupIndex].sub_groups[
              subGroupIndex
            ].id
          );
          this.showLoader();
          this.categories[categoryIndex].groups[groupIndex].sub_groups[
            subGroupIndex
          ].sub_group_items[itemIndex].quantity =
            orderInfo[indexToAddQuantity].quantity + 1;
          orderInfo[indexToAddQuantity].quantity =
            orderInfo[indexToAddQuantity].quantity + 1;
          localStorage.removeItem(this.cartName);
          localStorage.setItem(this.cartName, JSON.stringify(orderInfo));
          this.isLoadDone = true;
          this.hideLoader();
          EventBus.emit("ChangeItemQuantitySuccess");
        } else {
          if (this.isLoadingGetItemDetail == false) {
            if (this.isOnline) {
              this.addCartAnimation(
                mainItem.id,
                itemIndex,
                this.categories[categoryIndex].id,
                this.categories[categoryIndex].groups[groupIndex].id,
                this.categories[categoryIndex].groups[groupIndex].sub_groups[
                  subGroupIndex
                ].id
              );
              this.isLoadingGetItemDetail = true;
              this.showLoaderWithoutAnimation();
              let dataForm = {
                menuset_id: this.branch.menuset_id ?? 0,
                category_id: category_id,
                item_id: mainItem.id,
                branch_code: this.branchCode,
              };

              OrderService.getItemDetail(dataForm).then(
                (response) => {
                  if (response.data.result.isSuccess) {
                    this.categories[categoryIndex].groups[
                      groupIndex
                    ].sub_groups[subGroupIndex].sub_group_items[
                      itemIndex
                    ].quantity = 1;
                    let orderInfo =
                      JSON.parse(localStorage.getItem(this.cartName, null)) ??
                      [];
                    let itemDetail = response.data.data;
                    itemDetail.price_default = parseFloat(
                      itemDetail.price_default
                    );
                    itemDetail.member_price = parseFloat(
                      itemDetail.member_price
                    );

                    orderInfo.push(itemDetail);
                    localStorage.setItem(
                      this.cartName,
                      JSON.stringify(orderInfo)
                    );
                    EventBus.emit("ChangeItemQuantitySuccess");
                  } else {
                    this.snackbar = "danger";
                    this.message = response.data.result.message;
                    this.setTimeOutNotification();
                  }
                  this.isLoadDone = true;
                  this.hideLoaderWithoutAnimation();
                  this.isLoadingGetItemDetail = false;
                },
                (error) => {
                  this.snackbar = "danger";
                  this.message = error;
                  this.hideLoaderWithoutAnimation();
                  this.isLoadingGetItemDetail = false;
                }
              );
            } else {
              alert(this.textLostInternet);
            }
          }
        }
      }
    },

    subGroupOpenBoxChangeQuantityForItemWithoutModifier(
      groupIndex,
      itemIndex,
      categoryIndex,
      subGroupIndex,
      item,
      subGroupId
    ) {
      for (const category of this.categories) {
        for (const group of category.groups) {
          for (const subGroup of group.sub_groups) {
            for (const item of subGroup.sub_group_items) {
              delete item.openingEditQuantity;
            }
          }
        }
      }
      this.categories[categoryIndex].groups[groupIndex].sub_groups[
        subGroupIndex
      ].sub_group_items[itemIndex].openingEditQuantity = true;
      if (item) {
        this.subGroupHandleQuantityForMainItem(
          item,
          2,
          subGroupId,
          groupIndex,
          itemIndex,
          categoryIndex,
          subGroupIndex
        );
      }
    },

    addCartAnimation(
      itemId,
      itemIndex,
      categoryId,
      groupId = 0,
      subGroupId = 0
    ) {
      let prefixClass = categoryId;

      if (groupId) {
        prefixClass += "-" + groupId;
      }

      if (subGroupId) {
        prefixClass += "-" + subGroupId;
      }

      prefixClass += "-" + itemId;

      let item = $(".item-img-" + prefixClass);
      let img = item.find("img").attr("src");
      let itemX = item.offset().left;
      let itemY = item.offset().top;
      let itemWidth = item.width();

      $("#show")
        .css({
          left: itemX,
          top: itemY,
          width: itemWidth,
          height: itemWidth,
          opacity: 1,
          zIndex: 99999,
        })
        .find("img")
        .attr("src", img);

      TweenMax.to("#show", 0.25, {
        top: $(".footer").offset().top,
        left: $(window).width() / 2 - 40,
        width: 20,
      });
      TweenMax.to("#show", 0.15, {
        css: {
          opacity: 0,
        },
        delay: 0.15,
      });
    },

    showLoaderWithoutAnimation() {
      this.loaderActiveWithoutAnimation = true;
    },

    hideLoaderWithoutAnimation() {
      this.loaderActiveWithoutAnimation = false;
    },

    closeSnackbar() {
      this.snackbar = null;
    },
  },
};
</script>

<style scoped>
.content-custom {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: hidden;
}

.order-info {
  height: calc(100% - 180px);
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  margin-top: 85px;
  border-bottom: 0.5px solid #d3d3d3;
  padding-bottom: 10px;
}

.category-list {
  flex: 1;
  padding-right: 15px;
  overflow-y: auto;
  overflow-x: hidden;
  min-width: 200px;
}

.category-tag {
  border: 1px solid #d3d3d3;
  border-bottom: none;
  padding: 10px 5px;
  text-align: center;
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.category-tag:last-child {
  border-bottom: 1px solid #d3d3d3;
}

.category-tag .c-img {
  width: 70px;
  height: 70px;
  object-fit: cover;
}

.category-tag .c-name {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
  font-weight: bold;
  width: fit-content;
}

.category-tag.active .c-name {
  border-bottom: 5px solid #81ecec;
}

.content {
  flex: 3;
  overflow-y: auto;
  height: 100%;
}

.group-and-type-list {
  margin: 0 24px;
}

.category-detail.list,
.group-list.list,
.group-and-type-list.list {
  margin-left: 20px !important;
  padding-left: 0 !important;
}

.category-description {
  font-weight: normal;
  font-size: 11px;
  color: gray;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;
}

.group-list .group-tag {
  font-size: 13px;
  font-weight: bold;
  padding: 5px 25px;
  background: #f1f1f1;
  border-radius: 20px;
  white-space: pre;
  cursor: pointer;
}

.group-list .group-tag.active {
  background: var(--color);
}

.group-list .group-tag:not(:last-child) {
  margin-right: 15px;
}

.category-list::-webkit-scrollbar,
.group-list::-webkit-scrollbar,
.content::-webkit-scrollbar {
  display: none;
}

.item-list .tag-name {
  font-size: 18px;
  font-weight: bold;
}

.item-list .tag-name-category {
  font-size: 18px;
  font-weight: bold !important;
}

.item-list .tag-name-group {
  font-size: 16px;
  font-weight: bold;
}

.item-list .tag-name-sub-group {
  font-size: 14px;
  font-weight: bold;
}

input[type="search"] {
  border: none;
  background: transparent;
  margin: 0;
  padding: 7px 8px;
  font-size: 14px;
  color: inherit;
  border: 1px solid transparent;
  border-radius: inherit;
  background-color: #ffffff !important;
  border-radius: 50px;
  text-align: left !important;
}

input[type="search"]::placeholder {
  color: #bbb;
  text-align: left;
  font-weight: normal;
  font-size: 13px;
}

input[type="search"]:focus {
  outline: none;
  border: none;
}

input.nosubmit {
  width: 100%;
  height: 32px;
  padding: 5px 4px 5px 40px;
  background: transparent
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E")
    no-repeat 13px center;
}

.banner {
  position: relative;
}

.item-list > div:first-child {
  padding: 5px 0;
}

.item-list .item-img {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  width: 100%;
  aspect-ratio: 1 / 1;
  object-fit: cover;
}

.item-list .item-name {
  width: 100%;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.item-list .row .item-info {
  border: 1px solid #f1f1f1;
  border-radius: 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.item-list .row .item-info > div {
  padding: 10px;
}

/* .item-list .row > div > div:not(.item-not-available) {
  padding-bottom: 45px !important;
} */

.item-list .item-info > div {
  font-size: 17px;
  word-break: break-word;
}

.material-icons-round.active {
  color: var(--color);
}

.item-tag {
  display: flex;
}

.row-flex {
  display: flex;
  flex-wrap: wrap;
}

.row-flex > [class*="col-"] {
  margin-bottom: 15px;
}

.txt_popular {
  height: 100%;
  width: fit-content;
  background: var(--color);
  padding-left: 7px;
  padding-right: 7px;
  border-radius: 10px;
  margin-top: 2px;
  font-size: 11px !important;
  font-weight: normal !important;
}

.txt_popular_grid {
  height: 100%;
  width: fit-content;
  background: var(--color);
  padding-left: 7px;
  padding-right: 7px;
  border-radius: 10px;
  margin-top: 2px;
  font-size: 11px !important;
  font-weight: normal !important;
}

.member-price {
  color: var(--color) !important;
}

.price-defalut,
.member-price .price,
.grid .member-price .price {
  font-size: 15px !important;
}

.price-defalut.active,
.member-price.active {
  text-decoration: none;
}
.wrap-category > .custom-star {
  position: absolute;
  height: 20px !important;
  width: 20px !important;
  right: 5px;
  top: 5px;
}
.category-list .category-tag.active .custom-star {
  display: none !important;
}
.container-custom {
  display: flex;
  flex-direction: column;
  height: 100%;
}

footer {
  background-color: white;
  padding-top: 10px;
}
.addOverFlow {
  overflow-y: auto;
}
.removeOverFlow {
  overflow-y: auto;
}
.selectedItem {
  /* border-left: 2px solid var(--color); */
  /* padding-left: 5px; */
}
/* webkit solution */
::-webkit-input-placeholder {
  text-align: left !important;
}
/* mozilla solution */
input:-moz-placeholder {
  text-align: left !important;
}
.fs-11 {
  font-size: 11px !important;
}
.change-item-qty {
  width: auto !important;
}
.add-more-item {
  position: absolute;
  top: -4px;
  right: 10px;
  width: auto !important;
}
.item-not-available {
  opacity: 0.5 !important;
}

@media only screen and (max-width: 370px) {
  .item-list .list .item-tag .item-info {
    padding-bottom: 30px;
  }
}
</style>
