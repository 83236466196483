<template>
  <div class="container-custom" :style="styleObject">
    <div class="content-custom" v-on:scroll.passive='handleScroll($event)'>
      <header>
        <Header></Header>
      </header>
      <div class="order-info" :class="{ 'grid': typeList == 2 }">
        <div class="content" v-if="isLoadDone">
          <div class="i-search">
            <input
              v-model="searchKeyword"
              class="nosubmit"
              type="search"
              id="keyword_search"
              extraClass
              name="keyword_search"
              placeholder="Search for your items"
              @input="onChangesearchKeyword"
            />
          </div>
          <div id="show" class="position-absolute fly-cart">
            <img class="img-fluid" src="" alt="">
          </div>
          <div v-if="isSearch" style="padding: 5px 10px;">
            <div class="item-list">
              <div class="list m-0">
                <div
                  class="item-tag"
                  v-for="(item, index) in itemSearch"
                  :key="index"
                  @click="gotoOrderDetail(item.category_id, item.id, item.stock_balance, item.is_not_available, item.stock_enable_balance, item)"
                  :style="{ background: checkAndReturnSignature(item.tags) !== '' ? '#FFF8E4' : '', position: 'relative' }"
                  :class="{ 'selectedItem': getTotalQuantityById(item.id) > 0 }" 
                  style="position: relative"
                >
                  <div :style="{ position: 'relative', opacity: !checkAvailableItem(item) ? 0.5 : 1 }">
                    <div class="item-img" :class="'item-img-' + item.category_id + '-' + item.id">
                      <img :src="item.image" width="100" height="100" alt="error" style="border-radius: 5px;" />
                    </div>
                    <div class="item-info ms-2 w-100" style="height: 100%;">
                      <div style="line-height: normal;font-weight: unset;margin-top: -3px;font-size: 15px;">{{ item.item_name }}</div>
                      <div style="margin-top: 5px;">{{ item.description }}</div>
                      <div class="mt-1">
                        <div v-if="item.none_member_price" class="price-defalut active">{{ formatPrice(item.price_default) }}</div>
                        <div v-else>
                          <div class="price-defalut" :class="{ 'active': !isLogined }" :style="{ 'text-decoration': isLogined ? 'line-through' : 'none' }">{{ formatPrice(item.price_default) }}</div>
                          <div class="member-price" :class="{ 'active': isLogined }">
                            <span v-if="isLogined" class="font-weight-normal fs-11">
                              Member price <span class="price">{{ formatPrice(item.member_price) }}</span>
                            </span>
                            <span v-else class="font-weight-normal font-italic fs-11">Member price available</span>
                          </div>
                        </div>
                        <div style="display: flex;gap: 10px;">
                          <div class="txt_popular" v-if="checkAndReturnPopular(item.tags)">{{ checkAndReturnPopular(item.tags) }}</div>
                          <div class="txt_popular" v-if="checkAndReturnSignature(item.tags)">{{ checkAndReturnSignature(item.tags) }}</div>
                        </div>
                      </div>
                      <div v-if="!checkAvailableItem(item)" style="color: white;font-size: 14px;width: 100%;">NOT AVAILABLE</div>
                    </div>
                    <div class="change-item-qty">
                      <div v-if="checkAvailableItem(item) && (getTotalQuantityById(item.id) || item.quantityNew) > 0 && item.isHaveModifierOptionGroups == 0 && !item.openingEditQuantity" style="border: 0px;width: 30px;height: 30px;position: absolute;right: 0px;bottom: 10px;border: 1px solid var(--color);border-radius: 50px;">
                          <div style="width: auto;display: flex;align-items: center;justify-content: center;border:0px;">
                            <div @click.stop="openBoxChangeQuantityForItemWithoutModifierWhenSearch(index, null, null)" style="border:0px;width: 30px;height: 30px;align-items: center;justify-content: center;display: flex;">
                              <span style="color: var(--color);font-size: 13.1px;">{{ (getTotalQuantityById(item.id) || item.quantityNew) }}</span>
                            </div>
                          </div>
                      </div>
                      <div v-else-if="checkAvailableItem(item) && getTotalQuantityById(item.id) == 0 && item.isHaveModifierOptionGroups == 0 && item.openingEditQuantity" style="border: 0px;width: auto;height: 30px;position: absolute;right: 0px;bottom: 10px;border: 1px solid var(--color);border-radius: 50px;">
                          <div style="width: auto;display: flex;align-items: center;justify-content: center;border:0px;">
                            <div @click.stop="handleQuantityForMainItemWhenSearch(item, 1, index, item.category_id)" style="border:0px;width: 30px;height: 30px;align-items: center;justify-content: center;display: flex;margin-right: 5px;">
                              <span style="color: var(--color);font-size: 23px;margin-bottom: 2.5px;">-</span>
                            </div>
                            <div style="border:0px;min-width: 20px;height: 30px;align-items: center;justify-content: center;display: flex;margin-right: 5px;">
                              <span style="color: var(--color);font-size: 13.1px;">{{ getTotalQuantityById(item.id) }}</span>
                            </div>
                            <div @click.stop="handleQuantityForMainItemWhenSearch(item, 2, index, item.category_id)" style="border:0px;width: 30px;height: 30px;align-items: center;justify-content: center;display: flex;">
                              <span style="color: var(--color);font-size: 23px;margin-bottom: 2.5px;">+</span>
                            </div>
                          </div>
                      </div>
                      <div v-else-if="checkAvailableItem(item) && (getTotalQuantityById(item.id) || item.quantityNew) > 0 && item.isHaveModifierOptionGroups == 0 && item.openingEditQuantity" style="border: 0px;width: auto;height: 30px;position: absolute;right: 0px;bottom: 10px;border: 1px solid var(--color);border-radius: 50px;">
                          <div style="width: auto;display: flex;align-items: center;justify-content: center;border:0px;">
                            <div @click.stop="handleQuantityForMainItemWhenSearch(item, 1, index, item.category_id)" style="border:0px;width: 30px;height: 30px;align-items: center;justify-content: center;display: flex;margin-right: 5px;">
                              <span style="color: var(--color);font-size: 23px;margin-bottom: 2.5px;">-</span>
                            </div>
                            <div style="border:0px;min-width: 20px;height: 30px;align-items: center;justify-content: center;display: flex;margin-right: 5px;">
                              <span style="color: var(--color);font-size: 13.1px;">{{ (getTotalQuantityById(item.id) || item.quantityNew) }}</span>
                            </div>
                            <div @click.stop="handleQuantityForMainItemWhenSearch(item, 2, index, item.category_id)" style="border:0px;width: 30px;height: 30px;align-items: center;justify-content: center;display: flex;">
                              <span style="color: var(--color);font-size: 23px;margin-bottom: 2.5px;">+</span>
                            </div>
                          </div>
                      </div>
                      <div v-else-if="checkAvailableItem(item) && getTotalQuantityById(item.id) > 0 && item.isHaveModifierOptionGroups > 0" @click="gotoOrderDetail(item.category_id, item.id, item.stock_balance, item.is_not_available, item.stock_enable_balance, item)" style="width: 30px;height: 30px;border: 1px solid var(--color);border-radius: 30px;position: absolute;right: 0;bottom: 10px;align-items: center;justify-content: center;display: flex;">
                        <span style="color: var(--color);font-size: 13.1px;">{{ getTotalQuantityById(item.id) > 99 ? "99+" : getTotalQuantityById(item.id) }}</span>
                      </div>
                      <div v-else-if="checkAvailableItem(item) && getTotalQuantityById(item.id) == 0 && item.isHaveModifierOptionGroups == 0" @click.stop="openBoxChangeQuantityForItemWithoutModifierWhenSearch(index, item, item.category_id)" style="position: absolute;right: 0px;bottom: 10px;width: 30px;height: 30px;background-color: transparent;border: 1px solid var(--color) !important;border-radius: 30px;align-items: center;justify-content: center;display: flex;">
                        <span style="color: var(--color);font-size: 23px;margin-bottom: 2.5px;">+</span>
                      </div>
                      <div v-else-if="checkAvailableItem(item) && getTotalQuantityById(item.id) == 0 && item.isHaveModifierOptionGroups > 0" @click="gotoOrderDetail(item.category_id, item.id, item.stock_balance, item.is_not_available, item.stock_enable_balance, item)" style="position: absolute;right: 0px;bottom: 10px;width: 30px;height: 30px;background-color: transparent;border: 1px solid var(--color) !important;border-radius: 30px;align-items: center;justify-content: center;display: flex;">
                        <span style="color: var(--color);font-size: 23px;margin-bottom: 2.5px;">+</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="banner" v-if="slidesHomePage && slidesHomePage.length > 1">
              <swiper
                :slides-per-view="1"
                :space-between="0"
                :loop="true"
                :autoplay="{
                  delay: 3000,
                  disableOnInteraction: false,
                }"
                :initialSlide="0"
              >
                <swiper-slide v-for="(value, index) in slidesHomePage" :key="index">
                  <div class="slider">
                    <img
                      :src=value.file_url
                      alt="error"
                    />
                  </div>
                </swiper-slide>
              </swiper>
            </div>
            <div class="banner" v-else>
              <swiper
                :slides-per-view="1"
                :space-between="0"
                :autoplay="{
                  delay: 3000,
                  disableOnInteraction: false,
                }"
              >
                <swiper-slide v-for="(value, index) in slidesHomePage" :key="index">
                  <div class="slider">
                    <img
                      :src=value.file_url
                      alt="error"
                    />
                  </div>
                </swiper-slide>
              </swiper>
            </div>
            <div class="category-info" :class="{ 'list': typeList == 1 }">
              <!-- <div class="category-list" v-if="typeList == 2">
                <div
                  v-for="(category, index) in categories"
                  :key="index"
                  class="category-tag"
                  :id="'category-tag-' + category.id"
                  :class="[{ 'active': categorySelect.id == category.id }, 'tag-name-category-' + category.id]"
                  @click="selectCategory(category, index)"
                >
                  <div class="wrap-category">
                    <img :src="category.img_version" alt="error" />
                    <img v-if="category.is_feature"
                      class="custom-star"
                      src="@/assets/img/star.png"
                      alt="logo"
                    />
                  </div>
                  <div>{{ category.second_name }}</div>
                </div>
              </div> -->
              <div class="category-list" style="height: auto;margin-bottom: 10px;">
                <div
                  v-for="(category, index) in categories"
                  :key="index"
                  class="category-tag"
                  :id="'category-tag-' + category.id"
                  :class="'tag-name-category-' + category.id"
                  @click="selectCategory(category, index)"
                >
                  <div>{{ category.second_name }}</div>
                </div>
              </div>
              <div class="d-flex justify-content-between align-items-center group-and-type-list" :class="{ 'list': typeList == 1 }">
                <div
                  class="group-list me-2"
                  v-if="
                    categorySelect &&
                    categorySelect.groups
                  "
                >
                  <div
                    v-for="(group, index) in categorySelect.groups"
                    :key="index"
                    class="group-tag"
                    :id="'group-tag-' + group.id"
                    @click="selectGroup(group)"
                  >
                    {{ group.name }}
                  </div>
                </div>
                <div class="type-list d-flex justify-content-end">
                  <i
                    class="material-icons-round opacity-10 cursor-pointer"
                    :class="{ active: typeList == 1 }"
                    @click="changeTypeList(1)"
                    >format_list_bulleted</i
                  >
                  <i
                    class="material-icons-round opacity-10 ms-2 cursor-pointer"
                    :class="{ active: typeList == 2 }"
                    @click="changeTypeList(2)"
                    >window</i
                  >
                </div>
              </div>
              <div class="category-detail" :class="{ 'list': typeList == 1 }" v-if="categories.length">
                <div class="item-list" v-for="(category, index) in categories" :key="index">
                  <div>
                    <div class="tag-name-category" :id="'tag-name-category-' + category.id" style="align-items: center;">
                      <div class="d-flex">
                        {{ category.second_name }}                              
                        <img v-if="category.is_feature"
                          class="ms-2 me-3 mb-0"
                          style="width: 20px"
                          src="@/assets/img/star.png"
                          alt="logo"
                        />
                      </div>
                      <div class="category-description" v-if="category.details">
                        {{ category.details }}
                      </div>
                    </div>
                    <div class="list border-0 mt-1" v-if="typeList == 1">
                      <div
                        class="item-tag"
                        v-for="(item, index2) in category.items"
                        :key="index2"
                        :class="{ 'selectedItem': getTotalQuantityById(item.id) > 0 }" 
                        style="position: relative;"
                      >
                        <div :style="{ position: 'relative', opacity: !checkAvailableItem(item) ? 0.5 : 1 }" @click="gotoOrderDetail(category.id, item.id, item.stock_balance, item.is_not_available, item.stock_enable_balance, item)">
                          <div class="item-img" :class="'item-img-' + category.id + '-' + item.id">
                            <img :src="item.image" width="100" height="100" alt="error" style="border-radius: 5px;" />
                          </div>
                          <div class="item-info ms-2 w-100" style="height: 100%;">
                            <div style="line-height: normal;font-weight: unset;margin-top: -3px;font-size: 15px;">{{ item.item_name }}</div>
                            <div style="margin-top: 5px;">{{ item.description }}</div>
                            <div class="mt-1">
                              <div v-if="item.none_member_price" class="price-defalut active">{{ formatPrice(item.price_default) }}</div>
                              <div v-else>
                                <div class="price-defalut" :class="{ 'active': !isLogined }" :style="{ 'text-decoration': isLogined ? 'line-through' : 'none' }">{{ formatPrice(item.price_default) }}</div>
                                <div class="member-price" :class="{ 'active': isLogined }">
                                  <span v-if="isLogined" class="font-weight-normal fs-11">
                                    Member price <span class="price">{{ formatPrice(item.member_price) }}</span>
                                  </span>
                                  <span v-else class="font-weight-normal font-italic fs-11">Member price available</span>
                                </div>
                              </div>
                              <div style="display: flex;gap: 10px;">
                                <div class="txt_popular" v-if="checkAndReturnPopular(item.tags)">{{ checkAndReturnPopular(item.tags) }}</div>
                                <div class="txt_popular" v-if="checkAndReturnSignature(item.tags)">{{ checkAndReturnSignature(item.tags) }}</div>
                              </div>
                            </div>
                            <div v-if="!checkAvailableItem(item)" style="color: #eb2f06;font-size: 14px;width: 100%;">NOT AVAILABLE</div>
                          </div>
                        </div>
                        <div class="change-item-qty">
                          <div v-if="checkAvailableItem(item) && (getTotalQuantityById(item.id) || category.quantity) > 0 && item.isHaveModifierOptionGroups == 0 && !item.openingEditQuantity" style="border: 0px;width: 30px;height: 30px;position: absolute;right: 0px;bottom: 10px;border: 1px solid var(--color);border-radius: 50px;">
                              <div style="width: auto;display: flex;align-items: center;justify-content: center;border:0px;">
                                <div @click.stop="openBoxChangeQuantityForItemWithoutModifier(index, index2, null, null)" style="border:0px;width: 30px;height: 30px;align-items: center;justify-content: center;display: flex;">
                                  <span style="color: var(--color);font-size: 13.1px;">{{ (getTotalQuantityById(item.id) || category.quantity) }}</span>
                                </div>
                              </div>
                          </div>
                          <div v-else-if="checkAvailableItem(item) && getTotalQuantityById(item.id) == 0 && item.isHaveModifierOptionGroups == 0 && item.openingEditQuantity" style="border: 0px;width: auto;height: 30px;position: absolute;right: 0px;bottom: 10px;border: 1px solid var(--color);border-radius: 50px;">
                              <div style="width: auto;display: flex;align-items: center;justify-content: center;border:0px;">
                                <div @click.stop="handleQuantityForMainItem(item, 1, category.id, index, index2)" style="border:0px;width: 30px;height: 30px;align-items: center;justify-content: center;display: flex;margin-right: 5px;">
                                  <span style="color: var(--color);font-size: 23px;margin-bottom: 2.5px;">-</span>
                                </div>
                                <div style="border:0px;min-width: 20px;height: 30px;align-items: center;justify-content: center;display: flex;margin-right: 5px;">
                                  <span style="color: var(--color);font-size: 13.1px;">{{ getTotalQuantityById(item.id) }}</span>
                                </div>
                                <div @click.stop="handleQuantityForMainItem(item, 2, category.id, index, index2)" style="border:0px;width: 30px;height: 30px;align-items: center;justify-content: center;display: flex;">
                                  <span style="color: var(--color);font-size: 23px;margin-bottom: 2.5px;">+</span>
                                </div>
                              </div>
                          </div>
                          <div v-else-if="checkAvailableItem(item) && (getTotalQuantityById(item.id) || category.quantity) > 0 && item.isHaveModifierOptionGroups == 0 && item.openingEditQuantity" style="border: 0px;width: auto;height: 30px;position: absolute;right: 0px;bottom: 10px;border: 1px solid var(--color);border-radius: 50px;">
                              <div style="width: auto;display: flex;align-items: center;justify-content: center;border:0px;">
                                <div @click.stop="handleQuantityForMainItem(item, 1, category.id, index, index2)" style="border:0px;width: 30px;height: 30px;align-items: center;justify-content: center;display: flex;margin-right: 5px;">
                                  <span style="color: var(--color);font-size: 23px;margin-bottom: 2.5px;">-</span>
                                </div>
                                <div style="border:0px;min-width: 20px;height: 30px;align-items: center;justify-content: center;display: flex;margin-right: 5px;">
                                  <span style="color: var(--color);font-size: 13.1px;">{{ (getTotalQuantityById(item.id) || category.quantity) }}</span>
                                </div>
                                <div @click.stop="handleQuantityForMainItem(item, 2, category.id, index, index2)" style="border:0px;width: 30px;height: 30px;align-items: center;justify-content: center;display: flex;">
                                  <span style="color: var(--color);font-size: 23px;margin-bottom: 2.5px;">+</span>
                                </div>
                              </div>
                          </div>
                          <div v-else-if="checkAvailableItem(item) && getTotalQuantityById(item.id) > 0 && item.isHaveModifierOptionGroups > 0" @click="gotoOrderDetail(category.id, item.id, item.stock_balance, item.is_not_available, item.stock_enable_balance, item)" style="width: 30px;height: 30px;border: 1px solid var(--color);border-radius: 30px;position: absolute;right: 0;bottom: 10px;align-items: center;justify-content: center;display: flex;">
                            <span style="color: var(--color);font-size: 13.1px;">{{ getTotalQuantityById(item.id) > 99 ? "99+" : getTotalQuantityById(item.id) }}</span>
                          </div>
                          <div v-else-if="checkAvailableItem(item) && getTotalQuantityById(item.id) == 0 && item.isHaveModifierOptionGroups == 0" @click.stop="openBoxChangeQuantityForItemWithoutModifier(index, index2, item, category.id)" style="position: absolute;right: 0px;bottom: 10px;width: 30px;height: 30px;background-color: transparent;border: 1px solid var(--color) !important;border-radius: 30px;align-items: center;justify-content: center;display: flex;">
                            <span style="color: var(--color);font-size: 23px;margin-bottom: 2.5px;">+</span>
                          </div>
                          <div v-else-if="checkAvailableItem(item) && getTotalQuantityById(item.id) == 0 && item.isHaveModifierOptionGroups > 0" @click="gotoOrderDetail(category.id, item.id, item.stock_balance, item.is_not_available, item.stock_enable_balance, item)" style="position: absolute;right: 0px;bottom: 10px;width: 30px;height: 30px;background-color: transparent;border: 1px solid var(--color) !important;border-radius: 30px;align-items: center;justify-content: center;display: flex;">
                            <span style="color: var(--color);font-size: 23px;margin-bottom: 2.5px;">+</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="grid border-0" v-else>
                      <div class="row ms-0 me-0 row-flex">
                        <div
                          class="col-6"
                          :class="{ 'ps-0': index2 % 2 == 0, 'pe-0': index2 % 2 == 1 }"
                          v-for="(item, index2) in category.items"
                          :key="index2"
                          @click="gotoOrderDetail(category.id, item.id, item.stock_balance, item.is_not_available, item.stock_enable_balance, item)"
                          
                        >
                          <div class="col-12 text-center item-info h-100" :class="['item-img-' + category.id + '-' + item.id, {'item-not-available': !checkAvailableItem(item) }]" style="position: relative">
                            <img
                            :src="item.image"
                              alt="error"
                              style="width:100%;"
                            />
                            <div class="mt-2" style="line-height: normal;font-weight: unset;font-size: 15px;">{{ item.item_name }}</div>
                            <div style="display: flex;gap: 3px;margin-top: 5px;margin-bottom: 5px;align-items: center;justify-content: center;">
                              <div class="txt_popular_grid" v-if="checkAndReturnPopular(item.tags)">{{ checkAndReturnPopular(item.tags) }}</div>
                              <div class="txt_popular_grid" v-if="checkAndReturnSignature(item.tags)">{{ checkAndReturnSignature(item.tags) }}</div>
                            </div>
                            <div v-if="item.none_member_price" class="price-defalut active">{{ formatPrice(item.price_default) }}</div>
                            <div v-else>
                              <div class="price-defalut" :class="{ 'active': !isLogined }" :style="{ 'text-decoration': isLogined ? 'line-through' : 'none' }">{{ formatPrice(item.price_default) }}</div>
                              <div class="member-price" :class="{ 'active': isLogined }">
                                <span v-if="isLogined" class="font-weight-normal fs-11">
                                  Member price <span class="price">{{ formatPrice(item.member_price) }}</span>
                                </span>
                                <span v-else class="font-weight-normal font-italic fs-11">Member price available</span>
                              </div>
                            </div>
                            <div v-if="!checkAvailableItem(item)" style="color: #eb2f06;font-size: 14px;">NOT AVAILABLE</div>
                            <div class="change-item-qty add-more-item">
                              <div class="d-flex justify-content-center">
                                <div v-if="checkAvailableItem(item) && (getTotalQuantityById(item.id) || category.quantity) > 0 && item.isHaveModifierOptionGroups == 0 && !item.openingEditQuantity" style="border: 0px;width: 30px;height: 30px;border: 1px solid var(--color);border-radius: 50px;">
                                  <div style="width: auto;display: flex;align-items: center;justify-content: center;border:0px;">
                                    <div @click.stop="openBoxChangeQuantityForItemWithoutModifier(index, index2, null, null)" style="border:0px;width: 30px;height: 30px;align-items: center;justify-content: center;display: flex;">
                                      <span style="color: var(--color);font-size: 13.1px;">{{ (getTotalQuantityById(item.id) || category.quantity) }}</span>
                                    </div>
                                  </div>
                                </div>
                                <div v-else-if="checkAvailableItem(item) && getTotalQuantityById(item.id) == 0 && item.isHaveModifierOptionGroups == 0 && item.openingEditQuantity" style="border: 0px;width: auto;height: 30px;border: 1px solid var(--color);border-radius: 50px;">
                                  <div style="width: auto;display: flex;align-items: center;justify-content: center;border:0px;">
                                    <div @click.stop="handleQuantityForMainItem(item, 1, category.id, index, index2)" style="border:0px;width: 30px;height: 30px;align-items: center;justify-content: center;display: flex;margin-right: 5px;">
                                      <span style="color: var(--color);font-size: 23px;margin-bottom: 2.5px;">-</span>
                                    </div>
                                    <div style="border:0px;min-width: 20px;height: 30px;align-items: center;justify-content: center;display: flex;margin-right: 5px;">
                                      <span style="color: var(--color);font-size: 13.1px;">{{ getTotalQuantityById(item.id) }}</span>
                                    </div>
                                    <div @click.stop="handleQuantityForMainItem(item, 2, category.id, index, index2)" style="border:0px;width: 30px;height: 30px;align-items: center;justify-content: center;display: flex;">
                                      <span style="color: var(--color);font-size: 23px;margin-bottom: 2.5px;">+</span>
                                    </div>
                                  </div>
                                </div>
                                <div v-else-if="checkAvailableItem(item) && (getTotalQuantityById(item.id) || category.quantity) > 0 && item.isHaveModifierOptionGroups == 0 && item.openingEditQuantity" style="border: 0px;width: auto;height: 30px;border: 1px solid var(--color);border-radius: 50px;">
                                  <div style="width: auto;display: flex;align-items: center;justify-content: center;border:0px;">
                                    <div @click.stop="handleQuantityForMainItem(item, 1, category.id, index, index2)" style="border:0px;width: 30px;height: 30px;align-items: center;justify-content: center;display: flex;margin-right: 5px;">
                                      <span style="color: var(--color);font-size: 23px;margin-bottom: 2.5px;">-</span>
                                    </div>
                                    <div style="border:0px;min-width: 20px;height: 30px;align-items: center;justify-content: center;display: flex;margin-right: 5px;">
                                      <span style="color: var(--color);font-size: 13.1px;">{{ (getTotalQuantityById(item.id) || category.quantity) }}</span>
                                    </div>
                                    <div @click.stop="handleQuantityForMainItem(item, 2, category.id, index, index2)" style="border:0px;width: 30px;height: 30px;align-items: center;justify-content: center;display: flex;">
                                      <span style="color: var(--color);font-size: 23px;margin-bottom: 2.5px;">+</span>
                                    </div>
                                  </div>
                                </div>
                                <div v-else-if="checkAvailableItem(item) && getTotalQuantityById(item.id) > 0 && item.isHaveModifierOptionGroups > 0" @click="gotoOrderDetail(category.id, item.id, item.stock_balance, item.is_not_available, item.stock_enable_balance, item)" style="width: 30px;height: 30px;border: 1px solid var(--color);border-radius: 30px;align-items: center;justify-content: center;">
                                  <span style="color: var(--color);font-size: 13.1px;">{{ getTotalQuantityById(item.id) > 99 ? "99+" : getTotalQuantityById(item.id) }}</span>
                                </div>
                                <div v-else-if="checkAvailableItem(item) && getTotalQuantityById(item.id) == 0 && item.isHaveModifierOptionGroups == 0" @click.stop="openBoxChangeQuantityForItemWithoutModifier(index, index2, item, category.id)" style="width: 30px;height: 30px;background-color: transparent;border: 1px solid var(--color) !important;border-radius: 30px;align-items: center;justify-content: center;display: flex;">
                                  <span style="color: var(--color);font-size: 23px;margin-bottom: 2.5px;">+<a href="http://" target="_blank" rel="noopener noreferrer"></a></span>
                                </div>
                                <div v-else-if="checkAvailableItem(item) && getTotalQuantityById(item.id) == 0 && item.isHaveModifierOptionGroups > 0" @click="gotoOrderDetail(category.id, item.id, item.stock_balance, item.is_not_available, item.stock_enable_balance, item)" style="width: 30px;height: 30px;background-color: transparent;border: 1px solid var(--color) !important;border-radius: 30px;align-items: center;justify-content: center;display: flex;">
                                  <span style="color: var(--color);font-size: 23px;margin-bottom: 2.5px;">+</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="category.groups && category.groups.length">
                    <div class="item-list" v-for="(group, index3) in category.groups" :key="index3">
                      <div>
                        <div class="tag-name-group" :class="'tag-name-group-' + group.id">
                          {{ group.name }}
                        </div>
                        <div class="list border-0" v-if="typeList == 1">
                          <div
                            class="item-tag"
                            v-for="(item, index4) in group.items"
                            :key="index4"
                            @click="gotoOrderDetail(category.id, item.id, item.stock_balance, item.is_not_available, item.stock_enable_balance, item)"
                            :class="{ 'selectedItem': getTotalQuantityById(item.id) > 0 }"
                            style="position: relative;"
                          >
                            <div :style="{ position: 'relative', opacity: !checkAvailableItem(item) ? 0.5 : 1 }">
                              <div class="item-img" :class="'item-img-' + category.id + '-' + group.id + '-' + item.id">
                                <img :src="item.image" width="100" height="100" alt="error" style="border-radius: 5px;" />
                              </div>
                              <div class="item-info ms-2 w-100" style="height: 100%;">
                                <div style="line-height: normal;font-weight: unset;margin-top: -3px;font-size: 15px;">{{ item.item_name }}</div>
                                <div style="margin-top: 5px;">{{ item.description }}</div>
                                <div class="mt-1">
                                  <div v-if="item.none_member_price" class="price-defalut active">{{ formatPrice(item.price_default) }}</div>
                                  <div v-else>
                                    <div class="price-defalut" :class="{ 'active': !isLogined }" :style="{ 'text-decoration': isLogined ? 'line-through' : 'none' }">{{ formatPrice(item.price_default) }}</div>
                                    <div class="member-price" :class="{ 'active': isLogined }">
                                      <span v-if="isLogined" class="font-weight-normal fs-11">
                                        Member price <span class="price">{{ formatPrice(item.member_price) }}</span>
                                      </span>
                                      <span v-else class="font-weight-normal font-italic fs-11">Member price available</span>
                                    </div>
                                  </div>
                                  <div style="display: flex;gap: 10px;">
                                    <div class="txt_popular" v-if="checkAndReturnPopular(item.tags)">{{ checkAndReturnPopular(item.tags) }}</div>
                                    <div class="txt_popular" v-if="checkAndReturnSignature(item.tags)">{{ checkAndReturnSignature(item.tags) }}</div>
                                  </div>
                                </div>
                                <div v-if="!checkAvailableItem(item)" style="color: #eb2f06;font-size: 14px;width: 100%;">NOT AVAILABLE</div>
                              </div>
                            </div>
                            <div class="change-item-qty">
                              <div v-if="checkAvailableItem(item) && (getTotalQuantityById(item.id) || group.quantity) > 0 && item.isHaveModifierOptionGroups == 0 && !item.openingEditQuantity" style="border: 0px;width: 30px;height: 30px;position: absolute;right: 0px;bottom: 10px;border: 1px solid var(--color);border-radius: 50px;">
                                  <div style="width: auto;display: flex;align-items: center;justify-content: center;border:0px;">
                                    <div @click.stop="groupOpenBoxChangeQuantityForItemWithoutModifier(index3, index4, index, null, null)" style="border:0px;width: 30px;height: 30px;align-items: center;justify-content: center;display: flex;">
                                      <span style="color: var(--color);font-size: 13.1px;">{{ (getTotalQuantityById(item.id) || group.quantity) }}</span>
                                    </div>
                                  </div>
                              </div>
                              <div v-else-if="checkAvailableItem(item) && getTotalQuantityById(item.id) == 0 && item.isHaveModifierOptionGroups == 0 && item.openingEditQuantity" style="border: 0px;width: auto;height: 30px;position: absolute;right: 0px;bottom: 10px;border: 1px solid var(--color);border-radius: 50px;">
                                  <div style="width: auto;display: flex;align-items: center;justify-content: center;border:0px;">
                                    <div @click.stop="groupHandleQuantityForMainItem(item, 1, group.id, index3, index4, index)" style="border:0px;width: 30px;height: 30px;align-items: center;justify-content: center;display: flex;margin-right: 5px;">
                                      <span style="color: var(--color);font-size: 23px;margin-bottom: 2.5px;">-</span>
                                    </div>
                                    <div style="border:0px;min-width: 20px;height: 30px;align-items: center;justify-content: center;display: flex;margin-right: 5px;">
                                      <span style="color: var(--color);font-size: 13.1px;">{{ getTotalQuantityById(item.id) }}</span>
                                    </div>
                                    <div @click.stop="groupHandleQuantityForMainItem(item, 2, group.id, index3, index4, index)" style="border:0px;width: 30px;height: 30px;align-items: center;justify-content: center;display: flex;">
                                      <span style="color: var(--color);font-size: 23px;margin-bottom: 2.5px;">+</span>
                                    </div>
                                  </div>
                              </div>
                              <div v-else-if="checkAvailableItem(item) && (getTotalQuantityById(item.id) || group.quantity) > 0 && item.isHaveModifierOptionGroups == 0 && item.openingEditQuantity" style="border: 0px;width: auto;height: 30px;position: absolute;right: 0px;bottom: 10px;border: 1px solid var(--color);border-radius: 50px;">
                                  <div style="width: auto;display: flex;align-items: center;justify-content: center;border:0px;">
                                    <div @click.stop="groupHandleQuantityForMainItem(item, 1, group.id, index3, index4, index)" style="border:0px;width: 30px;height: 30px;align-items: center;justify-content: center;display: flex;margin-right: 5px;">
                                      <span style="color: var(--color);font-size: 23px;margin-bottom: 2.5px;">-</span>
                                    </div>
                                    <div style="border:0px;min-width: 20px;height: 30px;align-items: center;justify-content: center;display: flex;margin-right: 5px;">
                                      <span style="color: var(--color);font-size: 13.1px;">{{ (getTotalQuantityById(item.id) || group.quantity) }}</span>
                                    </div>
                                    <div @click.stop="groupHandleQuantityForMainItem(item, 2, group.id, index3, index4, index)" style="border:0px;width: 30px;height: 30px;align-items: center;justify-content: center;display: flex;">
                                      <span style="color: var(--color);font-size: 23px;margin-bottom: 2.5px;">+</span>
                                    </div>
                                  </div>
                              </div>
                              <div v-else-if="checkAvailableItem(item) && getTotalQuantityById(item.id) > 0 && item.isHaveModifierOptionGroups > 0" @click="gotoOrderDetail(category.id, item.id, item.stock_balance, item.is_not_available, item.stock_enable_balance, item)" style="width: 30px;height: 30px;border: 1px solid var(--color);border-radius: 30px;position: absolute;right: 0;bottom: 10px;align-items: center;justify-content: center;display: flex;">
                                <span style="color: var(--color);font-size: 13.1px;">{{ getTotalQuantityById(item.id) > 99 ? "99+" : getTotalQuantityById(item.id) }}</span>
                              </div>
                              <div v-else-if="checkAvailableItem(item) && getTotalQuantityById(item.id) == 0 && item.isHaveModifierOptionGroups == 0" @click.stop="groupOpenBoxChangeQuantityForItemWithoutModifier(index3, index4, index, item, group.id)" style="position: absolute;right: 0px;bottom: 10px;width: 30px;height: 30px;background-color: transparent;border: 1px solid var(--color) !important;border-radius: 30px;align-items: center;justify-content: center;display: flex;">
                                <span style="color: var(--color);font-size: 23px;margin-bottom: 2.5px;">+</span>
                              </div>
                              <div v-else-if="checkAvailableItem(item) && getTotalQuantityById(item.id) == 0 && item.isHaveModifierOptionGroups > 0" @click="gotoOrderDetail(category.id, item.id, item.stock_balance, item.is_not_available, item.stock_enable_balance, item)" style="position: absolute;right: 0px;bottom: 10px;width: 30px;height: 30px;background-color: transparent;border: 1px solid var(--color) !important;border-radius: 30px;align-items: center;justify-content: center;display: flex;">
                                <span style="color: var(--color);font-size: 23px;margin-bottom: 2.5px;">+</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="grid border-0" v-else>
                          <div class="row ms-0 me-0 row-flex">
                            <div
                              class="col-6"
                              :class="{ 'ps-0': index5 % 2 == 0, 'pe-0': index5 % 2 == 1 }"
                              v-for="(item, index5) in group.items"
                              :key="index5"
                              @click="gotoOrderDetail(category.id, item.id, item.stock_balance, item.is_not_available, item.stock_enable_balance, item)"
                            >
                              <div class="col-12 text-center item-info h-100" :class="['item-img-' + category.id + '-' + group.id + '-' + item.id, { 'item-not-available': !checkAvailableItem(item) }]" style="position: relative">
                                <img
                                :src="item.image"
                                  alt="error"
                                  style="width:100%;"
                                />
                                <div class="mt-2" style="line-height: normal;font-weight: unset;font-size: 15px;">{{ item.item_name }}</div>
                                <div style="display: flex;gap: 3px;margin-top: 5px;margin-bottom: 5px;align-items: center;justify-content: center;">
                                  <div class="txt_popular_grid" v-if="checkAndReturnPopular(item.tags)">{{ checkAndReturnPopular(item.tags) }}</div>
                                  <div class="txt_popular_grid" v-if="checkAndReturnSignature(item.tags)">{{ checkAndReturnSignature(item.tags) }}</div>
                                </div>
                                <div v-if="item.none_member_price" class="price-defalut active">{{ formatPrice(item.price_default) }}</div>
                                <div v-else>
                                  <div class="price-defalut" :class="{ 'active': !isLogined }" :style="{ 'text-decoration': isLogined ? 'line-through' : 'none' }">{{ formatPrice(item.price_default) }}</div>
                                  <div class="member-price" :class="{ 'active': isLogined }">
                                    <span v-if="isLogined" class="font-weight-normal fs-11">
                                      Member price <span class="price">{{ formatPrice(item.member_price) }}</span>
                                    </span>
                                    <span v-else class="font-weight-normal font-italic fs-11">Member price available</span>
                                  </div>
                                </div>
                                <div v-if="!checkAvailableItem(item)" style="color: #eb2f06;font-size: 14px;">NOT AVAILABLE</div>
                                <div class="change-item-qty add-more-item">
                                  <div class="d-flex justify-content-center">
                                    <div v-if="checkAvailableItem(item) && (getTotalQuantityById(item.id) || category.quantity) > 0 && item.isHaveModifierOptionGroups == 0 && !item.openingEditQuantity" style="border: 0px;width: 30px;height: 30px;border: 1px solid var(--color);border-radius: 50px;">
                                        <div style="width: auto;display: flex;align-items: center;justify-content: center;border:0px;">
                                          <div @click.stop="groupOpenBoxChangeQuantityForItemWithoutModifier(index3, index5, index, null, null)" style="border:0px;width: 30px;height: 30px;align-items: center;justify-content: center;display: flex;">
                                            <span style="color: var(--color);font-size: 13.1px;">{{ (getTotalQuantityById(item.id) || category.quantity) }}</span>
                                          </div>
                                        </div>
                                    </div>
                                    <div v-else-if="checkAvailableItem(item) && getTotalQuantityById(item.id) == 0 && item.isHaveModifierOptionGroups == 0 && item.openingEditQuantity" style="border: 0px;width: auto;height: 30px;border: 1px solid var(--color);border-radius: 50px;">
                                        <div style="width: auto;display: flex;align-items: center;justify-content: center;border:0px;">
                                          <div @click.stop="groupHandleQuantityForMainItem(item, 1, group.id, index3, index5, index)" style="border:0px;width: 30px;height: 30px;align-items: center;justify-content: center;display: flex;margin-right: 5px;">
                                            <span style="color: var(--color);font-size: 23px;margin-bottom: 2.5px;">-</span>
                                          </div>
                                          <div style="border:0px;min-width: 20px;height: 30px;align-items: center;justify-content: center;display: flex;margin-right: 5px;">
                                            <span style="color: var(--color);font-size: 13.1px;">{{ getTotalQuantityById(item.id) }}</span>
                                          </div>
                                          <div @click.stop="groupHandleQuantityForMainItem(item, 2, group.id, index3, index5, index)" style="border:0px;width: 30px;height: 30px;align-items: center;justify-content: center;display: flex;">
                                            <span style="color: var(--color);font-size: 23px;margin-bottom: 2.5px;">+</span>
                                          </div>
                                        </div>
                                    </div>
                                    <div v-else-if="checkAvailableItem(item) && (getTotalQuantityById(item.id) || category.quantity) > 0 && item.isHaveModifierOptionGroups == 0 && item.openingEditQuantity" style="border: 0px;width: auto;height: 30px;border: 1px solid var(--color);border-radius: 50px;">
                                        <div style="width: auto;display: flex;align-items: center;justify-content: center;border:0px;">
                                          <div @click.stop="groupHandleQuantityForMainItem(item, 1, group.id, index3, index5, index)" style="border:0px;width: 30px;height: 30px;align-items: center;justify-content: center;display: flex;margin-right: 5px;">
                                            <span style="color: var(--color);font-size: 23px;margin-bottom: 2.5px;">-</span>
                                          </div>
                                          <div style="border:0px;min-width: 20px;height: 30px;align-items: center;justify-content: center;display: flex;margin-right: 5px;">
                                            <span style="color: var(--color);font-size: 13.1px;">{{ (getTotalQuantityById(item.id) || category.quantity) }}</span>
                                          </div>
                                          <div @click.stop="groupHandleQuantityForMainItem(item, 2, group.id, index3, index5, index)" style="border:0px;width: 30px;height: 30px;align-items: center;justify-content: center;display: flex;">
                                            <span style="color: var(--color);font-size: 23px;margin-bottom: 2.5px;">+</span>
                                          </div>
                                        </div>
                                    </div>
                                    <div v-else-if="checkAvailableItem(item) && getTotalQuantityById(item.id) > 0 && item.isHaveModifierOptionGroups > 0" @click="gotoOrderDetail(category.id, item.id, item.stock_balance, item.is_not_available, item.stock_enable_balance, item)" style="width: 30px;height: 30px;border: 1px solid var(--color);border-radius: 30px;align-items: center;justify-content: center;">
                                      <span style="color: var(--color);font-size: 13.1px;">{{ getTotalQuantityById(item.id) > 99 ? "99+" : getTotalQuantityById(item.id) }}</span>
                                    </div>
                                    <div v-else-if="checkAvailableItem(item) && getTotalQuantityById(item.id) == 0 && item.isHaveModifierOptionGroups == 0" @click.stop="groupOpenBoxChangeQuantityForItemWithoutModifier(index3, index5, index, item, group.id)" style="width: 30px;height: 30px;background-color: transparent;border: 1px solid var(--color) !important;border-radius: 30px;align-items: center;justify-content: center;display: flex;">
                                      <span style="color: var(--color);font-size: 23px;margin-bottom: 2.5px;">+</span>
                                    </div>
                                    <div v-else-if="checkAvailableItem(item) && getTotalQuantityById(item.id) == 0 && item.isHaveModifierOptionGroups > 0" @click="gotoOrderDetail(category.id, item.id, item.stock_balance, item.is_not_available, item.stock_enable_balance, item)" style="width: 30px;height: 30px;background-color: transparent;border: 1px solid var(--color) !important;border-radius: 30px;align-items: center;justify-content: center;display: flex;">
                                      <span style="color: var(--color);font-size: 23px;margin-bottom: 2.5px;">+</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-if="group.sub_groups && group.sub_groups.length">
                        <div class="item-list" v-for="(subGroup, index6) in group.sub_groups" :key="index6">
                          <div>
                            <div class="tag-name-sub-group">
                              {{ subGroup.name }}
                            </div>
                            <div class="list border-0" v-if="typeList == 1">
                              <div
                                class="item-tag"
                                v-for="(item, index7) in subGroup.sub_group_items"
                                :key="index7"
                                @click="gotoOrderDetail(category.id, item.id, item.stock_balance, item.is_not_available, item.stock_enable_balance, item)"
                                :class="{ 'selectedItem': getTotalQuantityById(item.id) > 0 }" 
                                style="position: relative;"
                              >
                                <div :style="{ position: 'relative', opacity: !checkAvailableItem(item) ? 0.5 : 1 }">
                                  <div class="item-img" :class="'item-img-' + category.id + '-' + group.id + '-' + subGroup.id + '-' + item.id">
                                    <img :src="item.image" width="100" height="100" alt="error" style="border-radius: 5px;" />
                                  </div>
                                  <div class="item-info ms-2 w-100" style="height: 100%;">
                                    <div style="line-height: normal;font-weight: unset;margin-top: -3px;font-size: 15px;">{{ item.item_name }}</div>
                                    <div style="margin-top: 5px;">{{ item.description }}</div>
                                    <div class="mt-1">
                                      <div v-if="item.none_member_price" class="price-defalut active">{{ formatPrice(item.price_default) }}</div>
                                      <div v-else>
                                        <div class="price-defalut" :class="{ 'active': !isLogined }" :style="{ 'text-decoration': isLogined ? 'line-through' : 'none' }">{{ formatPrice(item.price_default) }}</div>
                                        <div class="member-price" :class="{ 'active': isLogined }">
                                          <span v-if="isLogined" class="font-weight-normal fs-11">
                                            Member price <span class="price">{{ formatPrice(item.member_price) }}</span>
                                          </span>
                                          <span v-else class="font-weight-normal font-italic fs-11">Member price available</span>
                                        </div>
                                      </div>
                                      <div style="display: flex;gap: 10px;">
                                        <div class="txt_popular" v-if="checkAndReturnPopular(item.tags)">{{ checkAndReturnPopular(item.tags) }}</div>
                                        <div class="txt_popular" v-if="checkAndReturnSignature(item.tags)">{{ checkAndReturnSignature(item.tags) }}</div>
                                      </div>
                                    </div>
                                    <div v-if="!checkAvailableItem(item)" style="color: #eb2f06;font-size: 14px;width: 100%;">NOT AVAILABLE</div>
                                  </div>
                                </div>
                                <div class="change-item-qty">
                                  <div v-if="checkAvailableItem(item) && (getTotalQuantityById(item.id) || subGroup.quantity) > 0 && item.isHaveModifierOptionGroups == 0 && !item.openingEditQuantity" style="border: 0px;width: 30px;height: 30px;position: absolute;right: 0px;bottom: 10px;border: 1px solid var(--color);border-radius: 50px;">
                                      <div style="width: auto;display: flex;align-items: center;justify-content: center;border:0px;">
                                        <div @click.stop="subGroupOpenBoxChangeQuantityForItemWithoutModifier(index3, index7, index, index6, null, null)" style="border:0px;width: 30px;height: 30px;align-items: center;justify-content: center;display: flex;">
                                          <span style="color: var(--color);font-size: 13.1px;">{{ (getTotalQuantityById(item.id) || subGroup.quantity) }}</span>
                                        </div>
                                      </div>
                                  </div>
                                  <div v-else-if="checkAvailableItem(item) && getTotalQuantityById(item.id) == 0 && item.isHaveModifierOptionGroups == 0 && item.openingEditQuantity" style="border: 0px;width: auto;height: 30px;position: absolute;right: 0px;bottom: 10px;border: 1px solid var(--color);border-radius: 50px;">
                                      <div style="width: auto;display: flex;align-items: center;justify-content: center;border:0px;">
                                        <div @click.stop="subGroupHandleQuantityForMainItem(item, 1, subGroup.id, index3, index7, index, index6)" style="border:0px;width: 30px;height: 30px;align-items: center;justify-content: center;display: flex;margin-right: 5px;">
                                          <span style="color: var(--color);font-size: 23px;margin-bottom: 2.5px;">-</span>
                                        </div>
                                        <div style="border:0px;min-width: 20px;height: 30px;align-items: center;justify-content: center;display: flex;margin-right: 5px;">
                                          <span style="color: var(--color);font-size: 13.1px;">{{ getTotalQuantityById(item.id) }}</span>
                                        </div>
                                        <div @click.stop="subGroupHandleQuantityForMainItem(item, 2, subGroup.id, index3, index7, index, index6)" style="border:0px;width: 30px;height: 30px;align-items: center;justify-content: center;display: flex;">
                                          <span style="color: var(--color);font-size: 23px;margin-bottom: 2.5px;">+</span>
                                        </div>
                                      </div>
                                  </div>
                                  <div v-else-if="checkAvailableItem(item) && (getTotalQuantityById(item.id) || subGroup.quantity) > 0 && item.isHaveModifierOptionGroups == 0 && item.openingEditQuantity" style="border: 0px;width: auto;height: 30px;position: absolute;right: 0px;bottom: 10px;border: 1px solid var(--color);border-radius: 50px;">
                                      <div style="width: auto;display: flex;align-items: center;justify-content: center;border:0px;">
                                        <div @click.stop="subGroupHandleQuantityForMainItem(item, 1, subGroup.id, index3, index7, index, index6)" style="border:0px;width: 30px;height: 30px;align-items: center;justify-content: center;display: flex;margin-right: 5px;">
                                          <span style="color: var(--color);font-size: 23px;margin-bottom: 2.5px;">-</span>
                                        </div>
                                        <div style="border:0px;min-width: 20px;height: 30px;align-items: center;justify-content: center;display: flex;margin-right: 5px;">
                                          <span style="color: var(--color);font-size: 13.1px;">{{ (getTotalQuantityById(item.id) || subGroup.quantity) }}</span>
                                        </div>
                                        <div @click.stop="subGroupHandleQuantityForMainItem(item, 2, subGroup.id, index3, index7, index, index6)" style="border:0px;width: 30px;height: 30px;align-items: center;justify-content: center;display: flex;">
                                          <span style="color: var(--color);font-size: 23px;margin-bottom: 2.5px;">+</span>
                                        </div>
                                      </div>
                                  </div>
                                  <div v-else-if="checkAvailableItem(item) && getTotalQuantityById(item.id) > 0 && item.isHaveModifierOptionGroups > 0" @click="gotoOrderDetail(category.id, item.id, item.stock_balance, item.is_not_available, item.stock_enable_balance, item)" style="width: 30px;height: 30px;border: 1px solid var(--color);border-radius: 30px;position: absolute;right: 0;bottom: 10px;align-items: center;justify-content: center;display: flex;">
                                    <span style="color: var(--color);font-size: 13.1px;">{{ getTotalQuantityById(item.id) > 99 ? "99+" : getTotalQuantityById(item.id) }}</span>
                                  </div>
                                  <div v-else-if="checkAvailableItem(item) && getTotalQuantityById(item.id) == 0 && item.isHaveModifierOptionGroups == 0" @click.stop="subGroupOpenBoxChangeQuantityForItemWithoutModifier(index3, index7, index, index6, item, subGroup.id)" style="position: absolute;right: 0px;bottom: 10px;width: 30px;height: 30px;background-color: transparent;border: 1px solid var(--color) !important;border-radius: 30px;align-items: center;justify-content: center;display: flex;">
                                    <span style="color: var(--color);font-size: 23px;margin-bottom: 2.5px;">+</span>
                                  </div>
                                  <div v-else-if="checkAvailableItem(item) && getTotalQuantityById(item.id) == 0 && item.isHaveModifierOptionGroups > 0" @click="gotoOrderDetail(category.id, item.id, item.stock_balance, item.is_not_available, item.stock_enable_balance, item)" style="position: absolute;right: 0px;bottom: 10px;width: 30px;height: 30px;background-color: transparent;border: 1px solid var(--color) !important;border-radius: 30px;align-items: center;justify-content: center;display: flex;">
                                    <span style="color: var(--color);font-size: 23px;margin-bottom: 2.5px;">+</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="grid border-0" v-else>
                              <div class="row ms-0 me-0 row-flex">
                                <div
                                  class="col-6"
                                  :class="{ 'ps-0': index8 % 2 == 0, 'pe-0': index8 % 2 == 1 }"
                                  v-for="(item, index8) in subGroup.sub_group_items"
                                  :key="index8"
                                  @click="gotoOrderDetail(category.id, item.id, item.stock_balance, item.is_not_available, item.stock_enable_balance, item)"
                                >
                                  <div class="col-12 text-center item-info h-100" :class="['item-img-' + category.id + '-' + group.id + '-' + subGroup.id + '-' + item.id, { 'item-not-available': !checkAvailableItem(item) }]" style="position: relative">
                                    <img
                                    :src="item.image"
                                      alt="error"
                                      style="width:100%;"
                                    />
                                    <div class="mt-2" style="line-height: normal;font-weight: unset;font-size: 15px;">{{ item.item_name }}</div>
                                    <div style="display: flex;gap: 3px;margin-top: 5px;margin-bottom: 5px;align-items: center;justify-content: center;">
                                      <div class="txt_popular_grid" v-if="checkAndReturnPopular(item.tags)">{{ checkAndReturnPopular(item.tags) }}</div>
                                      <div class="txt_popular_grid" v-if="checkAndReturnSignature(item.tags)">{{ checkAndReturnSignature(item.tags) }}</div>
                                    </div>
                                    <div v-if="item.none_member_price" class="price-defalut active">{{ formatPrice(item.price_default) }}</div>
                                    <div v-else>
                                      <div class="price-defalut" :class="{ 'active': !isLogined }" :style="{ 'text-decoration': isLogined ? 'line-through' : 'none' }">{{ formatPrice(item.price_default) }}</div>
                                      <div class="member-price" :class="{ 'active': isLogined }">
                                        <span v-if="isLogined" class="font-weight-normal fs-11">
                                          Member price <span class="price">{{ formatPrice(item.member_price) }}</span>
                                        </span>
                                        <span v-else class="font-weight-normal font-italic fs-11">Member price available</span>
                                      </div>
                                    </div>
                                    <div v-if="!checkAvailableItem(item)" style="color: white;font-size: 14px;">NOT AVAILABLE</div>
                                    <div class="change-item-qty add-more-item">
                                      <div class="d-flex justify-content-center">
                                        <div v-if="checkAvailableItem(item) && (getTotalQuantityById(item.id) || category.quantity) > 0 && item.isHaveModifierOptionGroups == 0 && !item.openingEditQuantity" style="border: 0px;width: 30px;height: 30px;border: 1px solid var(--color);border-radius: 50px;">
                                            <div style="width: auto;display: flex;align-items: center;justify-content: center;border:0px;">
                                              <div @click="subGroupOpenBoxChangeQuantityForItemWithoutModifier(index3, index8, index, index6, null, null)" style="border:0px;width: 30px;height: 30px;align-items: center;justify-content: center;display: flex;">
                                                <span style="color: var(--color);font-size: 13.1px;">{{ (getTotalQuantityById(item.id) || category.quantity) }}</span>
                                              </div>
                                            </div>
                                        </div>
                                        <div v-else-if="checkAvailableItem(item) && getTotalQuantityById(item.id) == 0 && item.isHaveModifierOptionGroups == 0 && item.openingEditQuantity" style="border: 0px;width: auto;height: 30px;border: 1px solid var(--color);border-radius: 50px;">
                                            <div style="width: auto;display: flex;align-items: center;justify-content: center;border:0px;">
                                              <div @click.stop="subGroupHandleQuantityForMainItem(item, 1, subGroup.id, index3, index8, index, index6)" style="border:0px;width: 30px;height: 30px;align-items: center;justify-content: center;display: flex;margin-right: 5px;">
                                                <span style="color: var(--color);font-size: 23px;margin-bottom: 2.5px;">-</span>
                                              </div>
                                              <div style="border:0px;min-width: 20px;height: 30px;align-items: center;justify-content: center;display: flex;margin-right: 5px;">
                                                <span style="color: var(--color);font-size: 13.1px;">{{ getTotalQuantityById(item.id) }}</span>
                                              </div>
                                              <div @click.stop="subGroupHandleQuantityForMainItem(item, 2, subGroup.id, index3, index8, index, index6)" style="border:0px;width: 30px;height: 30px;align-items: center;justify-content: center;display: flex;">
                                                <span style="color: var(--color);font-size: 23px;margin-bottom: 2.5px;">+</span>
                                              </div>
                                            </div>
                                        </div>
                                        <div v-else-if="checkAvailableItem(item) && (getTotalQuantityById(item.id) || category.quantity) > 0 && item.isHaveModifierOptionGroups == 0 && item.openingEditQuantity" style="border: 0px;width: auto;height: 30px;border: 1px solid var(--color);border-radius: 50px;">
                                            <div style="width: auto;display: flex;align-items: center;justify-content: center;border:0px;">
                                              <div @click.stop="subGroupHandleQuantityForMainItem(item, 1, subGroup.id, index3, index8, index, index6)" style="border:0px;width: 30px;height: 30px;align-items: center;justify-content: center;display: flex;margin-right: 5px;">
                                                <span style="color: var(--color);font-size: 23px;margin-bottom: 2.5px;">-</span>
                                              </div>
                                              <div style="border:0px;min-width: 20px;height: 30px;align-items: center;justify-content: center;display: flex;margin-right: 5px;">
                                                <span style="color: var(--color);font-size: 13.1px;">{{ (getTotalQuantityById(item.id) || category.quantity) }}</span>
                                              </div>
                                              <div @click.stop="subGroupHandleQuantityForMainItem(item, 2, subGroup.id, index3, index8, index, index6)" style="border:0px;width: 30px;height: 30px;align-items: center;justify-content: center;display: flex;">
                                                <span style="color: var(--color);font-size: 23px;margin-bottom: 2.5px;">+</span>
                                              </div>
                                            </div>
                                        </div>
                                        <div v-else-if="checkAvailableItem(item) && getTotalQuantityById(item.id) > 0 && item.isHaveModifierOptionGroups > 0" @click="gotoOrderDetail(category.id, item.id, item.stock_balance, item.is_not_available, item.stock_enable_balance, item)" style="width: 30px;height: 30px;border: 1px solid var(--color);border-radius: 30px;align-items: center;justify-content: center;">
                                          <span style="color: var(--color);font-size: 13.1px;">{{ getTotalQuantityById(item.id) > 99 ? "99+" : getTotalQuantityById(item.id) }}</span>
                                        </div>
                                        <div v-else-if="checkAvailableItem(item) && getTotalQuantityById(item.id) == 0 && item.isHaveModifierOptionGroups == 0" @click="subGroupOpenBoxChangeQuantityForItemWithoutModifier(index3, index8, index, index6, item, subGroup.id)" style="width: 30px;height: 30px;background-color: transparent;border: 1px solid var(--color) !important;border-radius: 30px;align-items: center;justify-content: center;display: flex;">
                                          <span style="color: var(--color);font-size: 23px;margin-bottom: 2.5px;">+</span>
                                        </div>
                                        <div v-else-if="checkAvailableItem(item) && getTotalQuantityById(item.id) == 0 && item.isHaveModifierOptionGroups > 0" @click="gotoOrderDetail(category.id, item.id, item.stock_balance, item.is_not_available, item.stock_enable_balance, item)" style="width: 30px;height: 30px;background-color: transparent;border: 1px solid var(--color) !important;border-radius: 30px;align-items: center;justify-content: center;display: flex;">
                                          <span style="color: var(--color);font-size: 23px;margin-bottom: 2.5px;">+</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="position-fixed top-1 end-1 message-success" style="z-index: 99999 !important">
        <material-snackbar
          v-if="snackbarAsMember === 'danger' || snackbarAsMember === 'success'"
          title=""
          date=""
          :description="messageAsMember"
          :icon="{ component: 'campaign', color: 'white' }"
          :color="snackbar"
          :close-handler="closeSnackbarAsMember"
        />
    </div>
    </div>
    <footer>
      <Footer :isYourFriendLogined="isYourFriendLogined"></Footer>
    </footer>
    <MemorizeYourBirthday />
    <material-loading :active="loaderActive"/>
    <material-loading-without-animation :active="loaderActiveWithoutAnimation" />
    <ModelCustomization :categoryId="categoryIdSelect" :itemId="itemIdSelect" :itemDetail="itemDetail" :isLogined="isLogined" :reloadModal="reloadModal" @clear-data-item="clearDataItem"/>
    <ModalNotification :message="'Your friend signed in as a Member!'"/>
    <ModalChooseNumberOfGuests :branchCode="branchCode" :tableCode="tableCode"/>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue';
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { Pagination, Autoplay } from "swiper";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import Header from "@/common/Header/OrderHeader";
import OrderService from "../services/order.service";
import $ from "jquery";
import "bootstrap/dist/js/bootstrap.bundle";
import "bootstrap/js/dist/modal";
import MemorizeYourBirthday from "../components/MemorizeYourBirthday.vue";
import Footer from "@/common/Footer/OrderFooter";
import MaterialLoading from '@/components/MaterialLoading.vue';
import ModelCustomization from './ModelCustomization.vue';
import EventBus from "vue3-eventbus";
import MaterialLoadingWithoutAnimation from '@/components/MaterialLoadingWithoutAnimation.vue';
import { TweenMax } from 'gsap';
import { localStorageName } from '@/config/utils';
import ModalNotification from '@/components/ModalNotification.vue';
import ModalChooseNumberOfGuests from '@/components/ModalChooseNumberOfGuests.vue';
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";

SwiperCore.use([Pagination, Autoplay]);

export default {
  name: "Order",
  components: {
    Swiper,
    SwiperSlide,
    Header,
    MemorizeYourBirthday,
    Footer,
    MaterialLoading,
    ModelCustomization,
    MaterialLoadingWithoutAnimation,
    ModalNotification,
    MaterialSnackbar,
    ModalChooseNumberOfGuests
  },
  data() {
    return {
      snackbarAsMember: null,
      messageAsMember: "",
      isLoadDone: false,
      loaderActive: false,
      merchantCode: "",
      memberInfo: JSON.parse(localStorage.getItem(localStorageName('memberInfo'), '')),
      branchCode: "",
      branch: JSON.parse(localStorage.getItem(localStorageName('BRANCH_INFO'), '')),
      pathType: "",
      tableCode: "",
      categories: [],
      categoryIdSelect: this.$route.params.category_id ?? 0,
      categorySelect: {},
      categoryIndexSelect: 1,
      groupSelect: null,
      typeList: 1,
      isSearch: false,
      searchKeyword: "",
      searchTimerId: null,
      itemSearch: [],
      slidesHomePage: [],
      itemIdSelect: 0,
      itemDetail: {},
      reloadModal: 'reload-item-detail',
      heightOrderContent: 'unset',
      isLogined: false,
      isYourFriendLogined: false,
      indexScrollCategory: 0,
      beforeIndexScrollCategory: 0,
      cartName: 'ORDER_INFO',
      isLoadingGetItemDetail: false,
      tableInfo: {},
      loaderActiveWithoutAnimation: false,
      isSelectCategory: false,
      textLostInternet: "Your internet connection has been lost, and items cannot be added to your cart at this time.",
      itemsAllowDisplay: []
    };
  },
  setup() {
    const isOnline = ref(navigator.onLine);

    const updateOnlineStatus = () => {
      isOnline.value = navigator.onLine;
    };

    onMounted(() => {
      window.addEventListener('online', updateOnlineStatus);
      window.addEventListener('offline', updateOnlineStatus);
    });

    onUnmounted(() => {
      window.removeEventListener('online', updateOnlineStatus);
      window.removeEventListener('offline', updateOnlineStatus);
    });

    return {
      isOnline,
    };
  },
  async created() {
    this.merchantCode = this.$store.state.merchantCode;
    this.branchCode = this.$store.state.branchCode;
    this.pathType = this.$route.params.type;
    this.tableCode = this.$route.params.table_code;
    this.cartName = await this.$store.dispatch('cartName');
    this.tableInfo = JSON.parse(localStorage.getItem('table_' + this.merchantCode.toLowerCase() + '_' + this.branchCode.toLowerCase() + '_' + this.tableCode.toLowerCase(), {}));

    if (this.memberInfo) {
      this.isYourFriendLogined = false;
    } else {
      if (this.tableInfo) {
        this.isYourFriendLogined = this.tableInfo.is_member_order ?? false;
      }
    }

    if(this.memberInfo || this.isYourFriendLogined) {
      this.isLogined = true;
    }
  },
  async mounted() {
    const memorize = this.$route.params.memorize ?? null;
    if (memorize) {
      $(".bottom_modal_memorize").click();
    }

    if (this.merchantCode && this.branchCode) {
      await this.getListCategory(1, true);
      await this.getSlidesHomePage();
    }

    if (this.isYourFriendLogined) {
      // $('.btn-notification-modal').click(); 
      this.snackbarAsMember = "success";
      this.messageAsMember = "Your friend signed in as a Member!";
      this.setTimeOutNotificationAsMember();
    }

    $(window).on('resize', this.resizeLayout);

    // EventBus.on('AddToCartThenReloadCategorySuccess', e => e.time == this.reloadModal && this.getListCategory(2)  )

    EventBus.on("AddToCartThenReloadCategorySuccess", (e) => {
      setTimeout(() => {
        if(e.time == this.reloadModal) {
          this.getListCategory(2, false)
        }
      }, 300);
    });

    if (this.tableInfo && !this.tableInfo.stripe_connect && !this.tableInfo.pax_selected && this.tableInfo.show_number_of_pax) {
      $('.btn-choose-number-of-guests-modal').click();
    }

    window.onpopstate = (e) => {
      if (this.itemIdSelect) {
        e.preventDefault();
        history.go(1);
      }
    };
  },
  computed: {
    styleObject() {
      return {
        "--color": this.$store.state.color,
      };
    },
  },
  methods: {
    formatPrice(price) {
      return 'S$ ' + parseFloat(price).toFixed(2);
    },

    clearDataItem() {
      this.itemIdSelect = 0;
      this.itemDetail = {};
    },

    handleScroll(event) {
      let top = event.srcElement.scrollTop;
      let elment = $('.category-list');
      let fixedHeight = $('.banner').height() + 44 + $('.order-header').height();
      let categoryId = 0;

      if (top > fixedHeight) {
        if (!elment.hasClass('fixed')) {
          $('.order-info').css('margin-top', $('.category-list').height());
          $('.order-info').css('height', "calc(100%)");
          elment.addClass('fixed');
        }
      } else {
        $('.order-info').css('margin-top', '0');
        $('.order-info').css('height', "100%");
        elment.removeClass('fixed');
      }

      //nav-links scroll
      const contents = $('.tag-name-category');
      const menus = $('.category-tag');
      var scrollPosition = $('.content-custom').scrollTop();
      var maxScrollPosition = $('.content-custom').prop('scrollHeight') - $('.content-custom').outerHeight();

      if (scrollPosition == Math.round(maxScrollPosition)) {
        const currentMenuActive = contents[contents.length - 1].getAttribute("id");

        if (this.isSelectCategory) {
          $('.category-tag').removeClass('active');
          $('#category-tag-' + this.categorySelect.id).addClass('active');
        } else {
          menus.each((index2, element) => {
            element.classList.remove("active");

            if (element.classList.contains(currentMenuActive)) {
              element.classList.add("active");
            }
          });
        }
      } else {
        contents.each((index, content) => {
          const contentTop = content.offsetTop;

          if (scrollPosition >= contentTop - 70) {
            const currentMenuActive = content.getAttribute("id");

            menus.each((index2, element) => {
              element.classList.remove("active");

              if (element.classList.contains(currentMenuActive)) {
                element.classList.add("active");
                categoryId = currentMenuActive.split('-')[3];

                if (index2 != this.indexScrollCategory) {
                  this.indexScrollCategory = index2
                }
              }
            });
          }
        });
      }

      if (this.indexScrollCategory != this.beforeIndexScrollCategory && categoryId) {
        this.scrollCenterHorizontal(this.indexScrollCategory, categoryId);
        this.beforeIndexScrollCategory = this.indexScrollCategory;
      }
    },

    resizeLayout() {
      setTimeout(() => {
        this.heightOrderContent = $( window ).height() - 112 - $('.banner').height() - $('.root_container').height();
        $('.content-custom').height(this.heightOrderContent);

        if (this.typeList == 1) {
          // $('.category-list').height(this.heightOrderContent - (this.heightOrderContent / 2) + 50);
          // $('.category-list').height('unset');
        } else {
          $('.category-list').height('unset');
        }
      }, 200)
    },

    gotoOrderDetail(categoryId, id, stock_balance, is_not_available, stock_enable_balance, item) {
      // console.log(stock_balance, is_not_available, stock_enable_balance, item)
      // if(this.checkAvailableItem(item) && item.isHaveModifierOptionGroups > 0) {
      if(this.checkAvailableItem(item)) {
        this.categoryIdSelect = categoryId
        this.itemIdSelect = id;

        // this.showLoader();
        let dataForm = {
          menuset_id: this.branch.menuset_id ?? 0,
          category_id: categoryId,
          item_id: id,
          branch_code: this.branchCode
        };

        OrderService.getItemDetail(dataForm).then(
          (response) => {
            if (response.data.result.isSuccess) {
              this.itemDetail = response.data.data;
              this.reloadModal = Math.random(32).toString();
              $('.btn-show-item-detail-modal').click();
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
            this.isLoadDone = true;
            // this.hideLoader();
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            // this.hideLoader();
          }
        );
      }
    },

    scrollCenterHorizontal(index, id) {
      if (index > 1) {
        const screenWidth = $( window ).width();
        setTimeout(() => {
          $('.category-list').animate({
            scrollLeft: $('#category-tag-' + id).offset().left + $('.category-list').scrollLeft() - (screenWidth / 3) - 20
          }, 300);
        }, 100);
      } else {
        setTimeout(() => {
          $('.category-list').animate({
            scrollLeft: 0
          }, 300);
        }, 100);
      }
    },

    selectCategory(category, index, isFirstLoad = false) {
      this.categorySelect = category;
      this.categoryIndexSelect = index;
      this.groupSelect = null;
      this.isSelectCategory = true;

      setTimeout(() => {
        var offsetTop = 0;
        $('#tag-name-category-' + category.id).each((index, element) => {
          offsetTop = element.offsetTop;
        })

        var scrollPosition = $('.content-custom').scrollTop();
        var maxScrollPosition = $('.content-custom').prop('scrollHeight') - $('.content-custom').outerHeight();
        
        if (scrollPosition == Math.round(maxScrollPosition)) {
          $('.category-tag').removeClass('active');
          $('#category-tag-' + category.id).addClass('active');
          this.categoryActive = true;
        }

        $('.content-custom').animate({
          scrollTop: offsetTop - 60
        }, 500);
      }, isFirstLoad ? 500 : 0);

      setTimeout(() => {
        this.isSelectCategory = false;
      }, isFirstLoad ? 1000 : 500)
    },

    selectGroup(group) {
      this.groupSelect = group;
      if (this.typeList == 2) {
        $(".group-list > .group-tag").removeClass("active");
        $("#group-tag-" + group.id).addClass("active");
        $('.content-custom').scrollTop(0,0);
        $('.content-custom').animate({
          scrollTop: $('.tag-name-group-' + group.id).offset().top - 80
        }, 500);
      } else {
        $(".group-list > .group-tag").removeClass("active");
        $("#group-tag-" + group.id).addClass("active");
        $('.content-custom').scrollTop(0,0);
        setTimeout(() => {
          $('.content-custom').animate({
            scrollTop: $('.tag-name-group-' + group.id).offset().top - (110 - $('.order-header').height())
          }, 500);
        }, 100)
      }
    },

    changeTypeList(type) {
      this.typeList = type;
    },

    async getListCategory(type, isLoadingAnimation) {
      if(isLoadingAnimation) {
        this.showLoader();
      }
      let dataForm = {
        menuset_id: this.branch.menuset_id ?? 0,
        branch_code: this.branch.branch_code ?? ''
      };

      await OrderService.getListCategory(dataForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.isLoadDone = true;
            this.categories = response.data.data.categories;
            this.itemsAllowDisplay = response.data.data.items_allow_display;

            if(type == 1) {
              if (this.categories.length) {
                if (this.categoryIdSelect) {
                  this.categories.forEach((category, index) => {
                    if (this.categoryIdSelect == category.id) {
                      this.selectCategory(category, index, true)
                      return;
                    }
                  });
                } else {
                  this.categorySelect = this.categories[0];
                  setTimeout(() => {
                    $('.tag-name-category-' + this.categorySelect.id).addClass('active');
                  }, 100)
                }
              }
            }
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.isLoadDone = true;
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
        }
      );
    },

    onChangesearchKeyword(event) {
      this.searchKeyword = event.target.value;
      if (this.searchKeyword.trim() == "") {
        this.isSearch = false;
      } else {
        this.isSearch = true;
        clearTimeout(this.searchTimerId);
        this.searchTimerId = setTimeout(() => {
          this.searchItem();
        }, 1000);
      }
    },

    searchItem() {
      this.showLoader();
      let dataForm = {
        menuset_id: this.branch.menuset_id ?? 0,
        search_keyword: this.searchKeyword,
        branch_code: this.branchCode,
        items_allow_display: this.itemsAllowDisplay
      };

      OrderService.searchItem(dataForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.itemSearch = response.data.data;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.isLoadDone = true;
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
        }
      );
    },

    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },

    showLoader() {
      this.loaderActive = true;
    },

    hideLoader() {
      this.loaderActive = false;
    },

    // gotoMyReward() {
    //   this.$router.push({ name: "MyReward", params: {
    //     merchant_code: this.merchantCode,
    //     type: this.pathType,
    //     branch_code: this.branchCode,
    //     table_code: this.tableCode
    //   } });
    // },

    checkAndReturnPopular(array) {
      if(array && array.length > 0) {
        if (array.includes("Popular")) {
          return "Popular";
        } else {
          return "";
        }
      }
      return "";
    },

    checkAndReturnSignature(array) {
      if(array && array.length > 0) {
        if (array.includes("Signature")) {
          return "Signature";
        } else {
          return "";
        }
      }
      return "";
    },

    async getSlidesHomePage() {
      this.showLoader();
      var dataForm = {
        isMintyApi: true,
        branch_code: this.branchCode,
        where_showing: 1,
        is_ordering_online: 1
      }
      await OrderService.getSlidesHomePage(dataForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.slidesHomePage = response.data.data
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.isLoadDone = true;
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
        }
      );
    },

    clickLink(link) {
      if(link && link != "") {
        window.open(link);
      }
    },

    checkAvailableItem(item) {
      if(item.stock_enable_balance) { // countdown = true
          if(item.stock_balance > 0) {
              return true;
          } else {
              return false;
          }
      } else { // countdown = false
          if(item.is_not_available == 0) {
              return true;
          } else {
              return false;
          }
      }
    },

    getTotalQuantityById(id) {
      const data = JSON.parse(localStorage.getItem(this.cartName, []));
      let totalQuantity = 0;
      if(data) {
        for (let i = 0; i < data.length; i++) {
            if (data[i].id === id) {
                totalQuantity += data[i].quantity;
            }
        }
      }
      return totalQuantity;
    },

    async handleQuantityForMainItem(mainItem, type, category_id, categoryIndex, itemIndex) { // 1 is - | 2 is +
      let orderInfo = JSON.parse(localStorage.getItem(this.cartName, null)) ?? [];
      const indexToAddQuantity = orderInfo.findIndex(item => item.id === mainItem.id);
      if (type == 1) {
        if (this.getTotalQuantityById(mainItem.id) === 1) {
            // If quantity is going to be decremented to 0, show confirm dialog
            if (confirm("Are you sure you want to remove this item '" + mainItem.item_name + "' from your cart?")) {
              this.showLoader();
              this.categories[categoryIndex].items[itemIndex].quantity = orderInfo[indexToAddQuantity].quantity - 1;
              orderInfo[indexToAddQuantity].quantity = orderInfo[indexToAddQuantity].quantity - 1;
              if (orderInfo[indexToAddQuantity].quantity == 0) {
                orderInfo.splice(indexToAddQuantity, 1);
              }
              localStorage.removeItem(this.cartName);
              localStorage.setItem(this.cartName, JSON.stringify(orderInfo));
              this.isLoadDone = true;
              this.hideLoader();
              EventBus.emit("AddToCartSuccess");
            }
        } else {
          if(orderInfo[indexToAddQuantity]) {
            if(orderInfo[indexToAddQuantity].quantity > 0) {
              this.showLoader();
              this.categories[categoryIndex].items[itemIndex].quantity = orderInfo[indexToAddQuantity].quantity - 1;
              orderInfo[indexToAddQuantity].quantity = orderInfo[indexToAddQuantity].quantity - 1;
              localStorage.removeItem(this.cartName);
              localStorage.setItem(this.cartName, JSON.stringify(orderInfo));
              this.isLoadDone = true;
              this.hideLoader();
              EventBus.emit("ChangeItemQuantitySuccess");
            }
          }
        }
      } else if (type == 2) {
        if(orderInfo[indexToAddQuantity]) {
          this.addCartAnimation(mainItem.id, itemIndex, category_id);
          this.showLoader();
          this.categories[categoryIndex].items[itemIndex].quantity = orderInfo[indexToAddQuantity].quantity + 1;
          orderInfo[indexToAddQuantity].quantity = orderInfo[indexToAddQuantity].quantity + 1;
          localStorage.removeItem(this.cartName);
          localStorage.setItem(this.cartName, JSON.stringify(orderInfo));
          this.isLoadDone = true;
          this.hideLoader();
          EventBus.emit("ChangeItemQuantitySuccess");
        } else {
          if(this.isLoadingGetItemDetail == false) {
            if(this.isOnline) {
              this.addCartAnimation(mainItem.id, itemIndex, category_id);
              this.isLoadingGetItemDetail = true;
              this.showLoaderWithoutAnimation();
              let dataForm = {
                menuset_id: this.branch.menuset_id ?? 0,
                category_id: category_id,
                item_id: mainItem.id,
                branch_code: this.branchCode
              };

              OrderService.getItemDetail(dataForm).then(
                (response) => {
                  if (response.data.result.isSuccess) {
                    this.categories[categoryIndex].items[itemIndex].quantity = 1;
                    let orderInfo = JSON.parse(localStorage.getItem(this.cartName, null)) ?? [];
                    let itemDetail = response.data.data;
                    itemDetail.price_default = parseFloat(itemDetail.price_default);
                    itemDetail.member_price = parseFloat(itemDetail.member_price);

                    orderInfo.push(itemDetail);
                    localStorage.setItem(this.cartName, JSON.stringify(orderInfo));
                    EventBus.emit("ChangeItemQuantitySuccess");
                  } else {
                    this.snackbar = "danger";
                    this.message = response.data.result.message;
                    this.setTimeOutNotification();
                  }
                  this.isLoadDone = true;
                  this.hideLoaderWithoutAnimation();
                  this.isLoadingGetItemDetail = false;
                },
                (error) => {
                  this.snackbar = "danger";
                  this.message = error;
                  this.hideLoaderWithoutAnimation();
                  this.isLoadingGetItemDetail = false;
                }
              );
            } else {
              alert(this.textLostInternet);
            }
          }
        }
      }
    },
    openBoxChangeQuantityForItemWithoutModifier(categoryIndex, itemIndex, item, category_id) {
      for (const category of this.categories) {
          for (const item of category.items) {
            delete item.openingEditQuantity;
          }
      }
      this.categories[categoryIndex].items[itemIndex].openingEditQuantity = true;
      if(item) {
        this.handleQuantityForMainItem(item, 2, category_id, categoryIndex, itemIndex);
      }
    },
    async groupHandleQuantityForMainItem(mainItem, type, category_id, groupIndex, itemIndex, categoryIndex) { // 1 is - | 2 is +
      let orderInfo = JSON.parse(localStorage.getItem(this.cartName, null)) ?? [];
      const indexToAddQuantity = orderInfo.findIndex(item => item.id === mainItem.id);
      if (type == 1) {
        if (this.getTotalQuantityById(mainItem.id) === 1) {
            // If quantity is going to be decremented to 0, show confirm dialog
            if (confirm("Are you sure you want to remove this item '" + mainItem.item_name + "' from your cart?")) {
              this.showLoader();
              this.categories[categoryIndex].groups[groupIndex].items[itemIndex].quantity = orderInfo[indexToAddQuantity].quantity - 1;
              orderInfo[indexToAddQuantity].quantity = orderInfo[indexToAddQuantity].quantity - 1;
              if (orderInfo[indexToAddQuantity].quantity == 0) {
                orderInfo.splice(indexToAddQuantity, 1);
              }
              localStorage.removeItem(this.cartName);
              localStorage.setItem(this.cartName, JSON.stringify(orderInfo));
              this.isLoadDone = true;
              this.hideLoader();
              EventBus.emit("AddToCartSuccess");
            }
        } else {
          if(orderInfo[indexToAddQuantity]) {
            if(orderInfo[indexToAddQuantity].quantity > 0) {
              this.showLoader();
              this.categories[categoryIndex].groups[groupIndex].items[itemIndex].quantity = orderInfo[indexToAddQuantity].quantity - 1;
              orderInfo[indexToAddQuantity].quantity = orderInfo[indexToAddQuantity].quantity - 1;
              localStorage.removeItem(this.cartName);
              localStorage.setItem(this.cartName, JSON.stringify(orderInfo));
              this.isLoadDone = true;
              this.hideLoader();
              EventBus.emit("ChangeItemQuantitySuccess");
            }
          }
        }
      } else if (type == 2) {
        if(orderInfo[indexToAddQuantity]) {
          this.addCartAnimation(mainItem.id, itemIndex, this.categories[categoryIndex].id, this.categories[categoryIndex].groups[groupIndex].id);
          this.showLoader();
          this.categories[categoryIndex].groups[groupIndex].items[itemIndex].quantity = orderInfo[indexToAddQuantity].quantity + 1;
          orderInfo[indexToAddQuantity].quantity = orderInfo[indexToAddQuantity].quantity + 1;
          localStorage.removeItem(this.cartName);
          localStorage.setItem(this.cartName, JSON.stringify(orderInfo));
          this.isLoadDone = true;
          this.hideLoader();
          EventBus.emit("ChangeItemQuantitySuccess");
        } else {
          if(this.isLoadingGetItemDetail == false) {
            if(this.isOnline) {
              this.addCartAnimation(mainItem.id, itemIndex, this.categories[categoryIndex].id, this.categories[categoryIndex].groups[groupIndex].id);
              this.isLoadingGetItemDetail = true;
              this.showLoaderWithoutAnimation();
              let dataForm = {
                menuset_id: this.branch.menuset_id ?? 0,
                category_id: category_id,
                item_id: mainItem.id,
                branch_code: this.branchCode
              };

              OrderService.getItemDetail(dataForm).then(
                (response) => {
                  if (response.data.result.isSuccess) {
                    this.categories[categoryIndex].groups[groupIndex].items[itemIndex].quantity = 1;
                    let orderInfo = JSON.parse(localStorage.getItem(this.cartName, null)) ?? [];
                    let itemDetail = response.data.data;
                    itemDetail.price_default = parseFloat(itemDetail.price_default);
                    itemDetail.member_price = parseFloat(itemDetail.member_price);

                    orderInfo.push(itemDetail);
                    localStorage.setItem(this.cartName, JSON.stringify(orderInfo));
                    EventBus.emit("ChangeItemQuantitySuccess");
                  } else {
                    this.snackbar = "danger";
                    this.message = response.data.result.message;
                    this.setTimeOutNotification();
                  }
                  this.isLoadDone = true;
                  this.hideLoaderWithoutAnimation();
                  this.isLoadingGetItemDetail = false;
                },
                (error) => {
                  this.snackbar = "danger";
                  this.message = error;
                  this.hideLoaderWithoutAnimation();
                  this.isLoadingGetItemDetail = false;
                }
              );
            } else {
              alert(this.textLostInternet);
            }
          }
        }
      }
    },
    groupOpenBoxChangeQuantityForItemWithoutModifier(groupIndex, itemIndex, categoryIndex, item, group_id) {
      for (const category of this.categories) {
          for (const group of category.groups) {
            for (const item of group.items) {
              delete item.openingEditQuantity;
            }
          }
      }
      this.categories[categoryIndex].groups[groupIndex].items[itemIndex].openingEditQuantity = true;
      if(item) {
        this.groupHandleQuantityForMainItem(item, 2, group_id, groupIndex, itemIndex, categoryIndex);
      }
    },
    async subGroupHandleQuantityForMainItem(mainItem, type, category_id, groupIndex, itemIndex, categoryIndex, subGroupIndex) { // 1 is - | 2 is +
      let orderInfo = JSON.parse(localStorage.getItem(this.cartName, null)) ?? [];
      const indexToAddQuantity = orderInfo.findIndex(item => item.id === mainItem.id);
      if (type == 1) {
        if (this.getTotalQuantityById(mainItem.id) === 1) {
            // If quantity is going to be decremented to 0, show confirm dialog
            if (confirm("Are you sure you want to remove this item '" + mainItem.item_name + "' from your cart?")) {
              this.showLoader();
              this.categories[categoryIndex].groups[groupIndex].sub_groups[subGroupIndex].sub_group_items[itemIndex].quantity = orderInfo[indexToAddQuantity].quantity - 1;
              orderInfo[indexToAddQuantity].quantity = orderInfo[indexToAddQuantity].quantity - 1;
              if (orderInfo[indexToAddQuantity].quantity == 0) {
                orderInfo.splice(indexToAddQuantity, 1);
              }
              localStorage.removeItem(this.cartName);
              localStorage.setItem(this.cartName, JSON.stringify(orderInfo));
              this.isLoadDone = true;
              this.hideLoader();
              EventBus.emit("AddToCartSuccess");
            }
        } else {
          if(orderInfo[indexToAddQuantity]) {
            if(orderInfo[indexToAddQuantity].quantity > 0) {
              this.showLoader();
              this.categories[categoryIndex].groups[groupIndex].sub_groups[subGroupIndex].sub_group_items[itemIndex].quantity = orderInfo[indexToAddQuantity].quantity - 1;
              orderInfo[indexToAddQuantity].quantity = orderInfo[indexToAddQuantity].quantity - 1;
              localStorage.removeItem(this.cartName);
              localStorage.setItem(this.cartName, JSON.stringify(orderInfo));
              this.isLoadDone = true;
              this.hideLoader();
              EventBus.emit("ChangeItemQuantitySuccess");
            }
          }
        }
      } else if (type == 2) {
        if(orderInfo[indexToAddQuantity]) {
          this.addCartAnimation(mainItem.id, itemIndex, this.categories[categoryIndex].id, this.categories[categoryIndex].groups[groupIndex].id, this.categories[categoryIndex].groups[groupIndex].sub_groups[subGroupIndex].id);
          this.showLoader();
          this.categories[categoryIndex].groups[groupIndex].sub_groups[subGroupIndex].sub_group_items[itemIndex].quantity = orderInfo[indexToAddQuantity].quantity + 1;
          orderInfo[indexToAddQuantity].quantity = orderInfo[indexToAddQuantity].quantity + 1;
          localStorage.removeItem(this.cartName);
          localStorage.setItem(this.cartName, JSON.stringify(orderInfo));
          this.isLoadDone = true;
          this.hideLoader();
          EventBus.emit("ChangeItemQuantitySuccess");
        } else {
          if(this.isLoadingGetItemDetail == false) {
            if(this.isOnline) {
              this.addCartAnimation(mainItem.id, itemIndex, this.categories[categoryIndex].id, this.categories[categoryIndex].groups[groupIndex].id, this.categories[categoryIndex].groups[groupIndex].sub_groups[subGroupIndex].id);
              this.isLoadingGetItemDetail = true;
              this.showLoaderWithoutAnimation();
              let dataForm = {
                menuset_id: this.branch.menuset_id ?? 0,
                category_id: category_id,
                item_id: mainItem.id,
                branch_code: this.branchCode
              };

              OrderService.getItemDetail(dataForm).then(
                (response) => {
                  if (response.data.result.isSuccess) {
                    this.categories[categoryIndex].groups[groupIndex].sub_groups[subGroupIndex].sub_group_items[itemIndex].quantity = 1;
                    let orderInfo = JSON.parse(localStorage.getItem(this.cartName, null)) ?? [];
                    let itemDetail = response.data.data;
                    itemDetail.price_default = parseFloat(itemDetail.price_default);
                    itemDetail.member_price = parseFloat(itemDetail.member_price);

                    orderInfo.push(itemDetail);
                    localStorage.setItem(this.cartName, JSON.stringify(orderInfo));
                    EventBus.emit("ChangeItemQuantitySuccess");
                  } else {
                    this.snackbar = "danger";
                    this.message = response.data.result.message;
                    this.setTimeOutNotification();
                  }
                  this.isLoadDone = true;
                  this.hideLoaderWithoutAnimation();
                  this.isLoadingGetItemDetail = false;
                },
                (error) => {
                  this.snackbar = "danger";
                  this.message = error;
                  this.hideLoaderWithoutAnimation();
                  this.isLoadingGetItemDetail = false;
                }
              );
            } else {
              alert(this.textLostInternet);
            }
          }
        }
      }
    },
    subGroupOpenBoxChangeQuantityForItemWithoutModifier(groupIndex, itemIndex, categoryIndex, subGroupIndex, item, subGroupId) {
      for (const category of this.categories) {
          for (const group of category.groups) {
            for (const subGroup of group.sub_groups) {
              for (const item of subGroup.sub_group_items) {
                delete item.openingEditQuantity;
              }
            }
          }
      }
      this.categories[categoryIndex].groups[groupIndex].sub_groups[subGroupIndex].sub_group_items[itemIndex].openingEditQuantity = true;
      if(item) {
        this.subGroupHandleQuantityForMainItem(item, 2, subGroupId, groupIndex, itemIndex, categoryIndex, subGroupIndex);
      }
    },
    openBoxChangeQuantityForItemWithoutModifierWhenSearch(itemIndex, item, category_id) {
      for (const item of this.itemSearch) {
        delete item.openingEditQuantity;
      }
      this.itemSearch[itemIndex].openingEditQuantity = true;
      if(item) {
        this.handleQuantityForMainItemWhenSearch(item, 2, itemIndex, category_id);
      }
    },
    async handleQuantityForMainItemWhenSearch(mainItem, type, itemIndex, category_id) { // 1 is - | 2 is +
      let orderInfo = JSON.parse(localStorage.getItem(this.cartName, null)) ?? [];
      const indexToAddQuantity = orderInfo.findIndex(item => item.id === mainItem.id);
      if (type == 1) {
        if (this.getTotalQuantityById(mainItem.id) === 1) {
            // If quantity is going to be decremented to 0, show confirm dialog
            if (confirm("Are you sure you want to remove this item '" + mainItem.item_name + "' from your cart?")) {
              this.showLoader();
              this.itemSearch[itemIndex].quantityNew = orderInfo[indexToAddQuantity].quantity - 1;
              orderInfo[indexToAddQuantity].quantity = orderInfo[indexToAddQuantity].quantity - 1;
              if (orderInfo[indexToAddQuantity].quantity == 0) {
                orderInfo.splice(indexToAddQuantity, 1);
              }
              localStorage.removeItem(this.cartName);
              localStorage.setItem(this.cartName, JSON.stringify(orderInfo));
              this.isLoadDone = true;
              this.hideLoader();
              EventBus.emit("AddToCartSuccess");
            }
        } else {
          if(orderInfo[indexToAddQuantity]) {
            if(orderInfo[indexToAddQuantity].quantity > 0) {
              this.showLoader();
              this.itemSearch[itemIndex].quantityNew = orderInfo[indexToAddQuantity].quantity - 1;
              orderInfo[indexToAddQuantity].quantity = orderInfo[indexToAddQuantity].quantity - 1;
              localStorage.removeItem(this.cartName);
              localStorage.setItem(this.cartName, JSON.stringify(orderInfo));
              this.isLoadDone = true;
              this.hideLoader();
              EventBus.emit("ChangeItemQuantitySuccess");
            }
          }
        }
      } else if (type == 2) {
        if(orderInfo[indexToAddQuantity]) {
          this.addCartAnimation(mainItem.id, itemIndex, category_id);
          this.showLoader();
          this.itemSearch[itemIndex].quantityNew = orderInfo[indexToAddQuantity].quantity + 1;
          orderInfo[indexToAddQuantity].quantity = orderInfo[indexToAddQuantity].quantity + 1;
          localStorage.removeItem(this.cartName);
          localStorage.setItem(this.cartName, JSON.stringify(orderInfo));
          this.isLoadDone = true;
          this.hideLoader();
          EventBus.emit("ChangeItemQuantitySuccess");
        } else {
          if(this.isLoadingGetItemDetail == false) {
            if(this.isOnline) {
              this.addCartAnimation(mainItem.id, itemIndex, category_id);
              this.isLoadingGetItemDetail = true;
              this.showLoaderWithoutAnimation();
              let dataForm = {
                menuset_id: this.branch.menuset_id ?? 0,
                category_id: category_id,
                item_id: mainItem.id,
                branch_code: this.branchCode
              };

              OrderService.getItemDetail(dataForm).then(
                (response) => {
                  if (response.data.result.isSuccess) {
                    this.itemSearch[itemIndex].quantityNew = 1;
                    let orderInfo = JSON.parse(localStorage.getItem(this.cartName, null)) ?? [];
                    let itemDetail = response.data.data;
                    itemDetail.price_default = parseFloat(itemDetail.price_default);
                    itemDetail.member_price = parseFloat(itemDetail.member_price);

                    orderInfo.push(itemDetail);
                    localStorage.setItem(this.cartName, JSON.stringify(orderInfo));
                    EventBus.emit("ChangeItemQuantitySuccess");
                  } else {
                    this.snackbar = "danger";
                    this.message = response.data.result.message;
                    this.setTimeOutNotification();
                  }
                  this.isLoadDone = true;
                  this.hideLoaderWithoutAnimation();
                  this.isLoadingGetItemDetail = false;
                },
                (error) => {
                  this.snackbar = "danger";
                  this.message = error;
                  this.hideLoaderWithoutAnimation();
                  this.isLoadingGetItemDetail = false;
                }
              );
            } else {
              alert(this.textLostInternet);
            }
          }
        }
      }
    },

    addCartAnimation(itemId, itemIndex, categoryId, groupId = 0, subGroupId = 0) {
      let prefixClass = categoryId;

      if (groupId) {
        prefixClass += '-' + groupId;
      }

      if (subGroupId) {
        prefixClass += '-' + subGroupId;
      }

      prefixClass += '-' + itemId

      let item = $('.item-img-' + prefixClass);
      let img = item.find("img").attr("src");
      let itemX = item.offset().left;
      let itemY = item.offset().top;
      let itemWidth = item.width();

      $("#show")
      .css({
        left: itemX,
        top: itemY,
        width: itemWidth,
        height: itemWidth,
        opacity: 1,
        zIndex: 99999
      })
      .find("img").attr("src", img)

      TweenMax.to("#show", 0.25, {top: $('.root_order_total').offset().top, left: $('.root_order_total').offset().left + 35, width: 20});
      TweenMax.to("#show", .15, {
        css:{
          opacity: 0
        }, delay:0.15})
    },

    showLoaderWithoutAnimation() {
      this.loaderActiveWithoutAnimation = true;
    },

    hideLoaderWithoutAnimation() {
      this.loaderActiveWithoutAnimation = false;
    },
    setTimeOutNotificationAsMember() {
      setTimeout(() => {
        this.snackbarAsMember = null;
      }, 10000);
    },
    closeSnackbarAsMember() {
        this.snackbarAsMember = null;
    },
  },
  watch: {
    isLoadDone(val) {
      if (val) {
        this.resizeLayout();
      }
    }
  }
};
</script>

<style scoped>
.order-info {
  height: 100%;
}

.i-search {
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.i-search {
  background: var(--color);
  padding-bottom: 5px;
}

.category-info {
  /* overflow-y: auto; */
  padding-top: 10px;
}

.category-list,
.group-list {
  display: flex;
  width: 100%;
  overflow-x: auto;
}

.category-list {
  padding-bottom: 5px;
  background: #ffffff;
}

.category-list.list {
  position: absolute;
  left: 0;
  width: 85px !important;
  flex-direction: column;
  padding: 0;
  overflow-y: auto;
  border-right: 3px solid var(--color);
  padding-bottom: calc((100vh - 407px) / 2);
}

.category-list.fixed {
  position: fixed;
  top: 0;
  z-index: 9;
}

.group-and-type-list {
  margin: 0 24px;
}

.category-detail.list,
.group-list.list,
.group-and-type-list.list {
  margin-left: 20px !important;
  padding-left: 0 !important;
}

.category-description {
  font-weight: normal;
  font-size: 11px;
}

.category-list .category-tag {
  /* width: 100px;
  min-width: 100px; */
  width: fit-content;
  min-width: fit-content;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 15px;
  cursor: pointer;
  border-bottom: 1px solid lightgray;
}

.category-list.list .category-tag {
  width: 75px;
  min-width: 75px;
  margin-right: 0 !important;
}

.category-list.list .category-tag:last-child,
.category-detail.list .item-list:last-child {
  margin-bottom: 20px;
}

.category-list.list .category-tag {
  margin-top: 10px;
  
}

.category-list .category-tag {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.category-list .category-tag.active {
  color: var(--color) !important;
  border-color: var(--color) !important;
}

.category-list .category-tag.active img {
  border: 2px solid #25ccf7;
}

.category-list .category-tag img {
  width: 45px;
  height: 45px;
  border-radius: 10px;
}

.category-list .category-tag > div:last-child {
  font-size: 15px;
  line-height: 15px;
}

.group-list .group-tag {
  font-size: 13px;
  font-weight: bold;
  padding: 5px 25px;
  background: #f1f1f1;
  border-radius: 20px;
  white-space: pre;
  cursor: pointer;
}

.group-list .group-tag.active {
  background: var(--color);
}

.group-list .group-tag:not(:last-child) {
  margin-right: 15px;
}

.category-list::-webkit-scrollbar,
.group-list::-webkit-scrollbar {
  display: none;
}

.item-list .tag-name {
  font-size: 18px;
  font-weight: bold;
}

.item-list .tag-name-category {
  font-size: 18px;
  font-weight: bold !important;
}

.item-list .tag-name-group {
  font-size: 16px;
  font-weight: bold;
}

.item-list .tag-name-sub-group {
  font-size: 14px;
  font-weight: bold;
}

input[type="search"] {
  border: none;
  background: transparent;
  margin: 0;
  padding: 7px 8px;
  font-size: 14px;
  color: inherit;
  border: 1px solid transparent;
  border-radius: inherit;
  background-color: #ffffff !important;
  border-radius: 50px;
  text-align: left !important;
}

input[type="search"]::placeholder {
  color: #bbb;
  text-align: left;
  font-weight: normal;
  font-size: 13px;
}

input[type="search"]:focus {
  outline: none;
  border: none;
}

input.nosubmit {
  width: 100%;
  height: 32px;
  padding: 5px 4px 5px 40px;
  background: transparent
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E")
    no-repeat 13px center;
}

.banner {
  position: relative;
}

.item-list > div:first-child {
  padding: 5px 0;
  border-bottom: 0.5px solid #f1f1f1;
}

.item-list .grid .row > div > div {
  border: 1px solid #f1f1f1;
  border-radius: 15px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  padding: 10px;
}

.item-list .grid .row > div > div:not(.item-not-available) {
  padding-bottom: 45px !important;
}

.item-list .list > div > div {
  display: flex;
  width: 100%;
  padding: 7px 0;
  border-bottom: 2px solid #f1f1f1;
  font-size: 11px;
}

.item-list .list > div:last-child > div {
  border-bottom: none;
}

.item-list .list > div.active {
  background-color: #fff6e4;
}

.item-list > div:first-child {
  margin: 0 24px;
}

.category-detail.list .item-list > div:first-child,
.type-list.list {
  margin: 0 24px 0 0;
}

.item-list .list > div .item-info div:first-child,
.item-list .list > div .item-info div:last-child,
.item-list .grid .item-info > div {
  font-size: 17px;
  word-break: break-word;
}

.item-list .list > div .item-info > div:first-child {
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.material-icons-round.active {
  color: var(--color);
}

.item-tag {
  display: flex;
}

.row-flex {
  display: flex;
  flex-wrap: wrap;
}

.row-flex > [class*="col-"] {
  margin-bottom: 15px;
}

.txt_popular {
  height: 100%;width: fit-content;background: var(--color);padding-left: 7px;padding-right: 7px;border-radius: 10px;margin-top: 2px; font-size: 11px !important;font-weight: normal !important;
}

.txt_popular_grid {
  height: 100%;width: fit-content;background: var(--color);padding-left: 7px;padding-right: 7px;border-radius: 10px;margin-top: 2px;font-size:11px !important;font-weight: normal !important;
}

.member-price{
  color: var(--color) !important;
}

.price-defalut,
.member-price .price,
.grid .member-price .price {
  font-size: 13.5px !important;
}

.price-defalut.active,
.member-price.active {
  text-decoration: none;
}
.wrap-category {
  position: relative;
}
.wrap-category > .custom-star {
  position: absolute;
  height: 20px !important;
  width: 20px !important;
  right: 0px;
  top: -5px;
}
.category-list .category-tag.active .custom-star {
  display: none !important;
}
.container-custom {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.content-custom {
    flex: 1;
    background-color: white;
    overflow-x: auto;
}
footer {
  background-color: white;
  padding-top: 10px;
}
.addOverFlow {
  overflow-y: auto
}
.removeOverFlow {
  overflow-y: auto
}
.selectedItem {
  /* border-left: 2px solid var(--color); */
  /* padding-left: 5px; */
}
/* webkit solution */
::-webkit-input-placeholder {
  text-align:left !important;
}
/* mozilla solution */
input:-moz-placeholder {
  text-align:left !important;
}
.fs-11 {
  font-size: 11px !important;
}
.change-item-qty {
  width: auto !important;
}
.add-more-item {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 10px;
  width: auto !important;
}
.item-not-available {
  opacity: 0.5 !important;
}

@media only screen and (max-width: 370px) {
  .item-list .list .item-tag .item-info {
    padding-bottom: 30px;
  }
}
</style>
