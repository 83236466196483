import api from "./api";

class KioskService {
  verifyReaderState(dataForm) {
    return api.post("/kiosk/verify-reader-state", dataForm);
  }

  createPaymentIntent(dataForm) {
    return api.post("/kiosk/create-payment-intent", dataForm);
  }

  requestPaymentReader(dataForm) {
    return api.post("/kiosk/request-payment-reader", dataForm);
  }

  cancelPaymentIntent(dataForm) {
    return api.post("/kiosk/cancel-payment-intent", dataForm);
  }

  cancelReaderAction(dataForm) {
    return api.post("/kiosk/cancel-reader-action", dataForm);
  }

  saveOrder(dataForm) {
    return api.post("/kiosk/save-order", dataForm);
  }

  checkPaymentIntentStatus(dataForm) {
    return api.post("/kiosk/check-payment-intent-status", dataForm);
  }

  getOrderStatus(dataForm) {
    return api.post("/kiosk/get-order-status", dataForm);
  }

  getOrderDetail(dataForm) {
    return api.post("/kiosk/get-order-detail", dataForm);
  }

  retryOrder(dataForm) {
    return api.post("/kiosk/retry-order", dataForm);
  }
}

export default new KioskService();
