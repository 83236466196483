<template>
  <header>
    <div class="d-flex justify-center header">
      <img src="@/assets/img/logos/WH_Logo.gif" alt="logo" />
      <div class="title">WOK HEY</div>
    </div>
    <div class="d-flex justify-content-between align-items-center" v-if="isCheckoutPage">
      <div class="font-weight-bold ps-3">YOUR ORDER</div>
      <div class="pe-3" style="font-size: 11px">
        Waiting time less than 10 mins
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "KioskHeader",
  props: {
    isCheckoutPage: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped>
header {
  position: fixed;
  width: 100%;
  margin-bottom: 1rem;
  z-index: 2;
}

header > div:nth-child(2) {
  background: #000;
  color: #fff;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fbc743;
  height: 70px;
}

.header .title {
  font-size: 28px;
  font-weight: bold;
}

.header img {
  height: 70px;
  width: auto;
}
</style>
