<template>
  <div
    class="page-header align-items-start"
    style="font-size: 14px"
    :style="styleObject"
  >
    <span class="mask bg-white"></span>
    <div
      class="container mw-100"
      style="padding-left: 0px; padding-right: 0px"
      @click="hideDiv($event)"
    >
      <Header :isCheckoutPage="true"></Header>
      <div class="content">
        <div class="card-body">
          <div
            class="div-order-items"
            v-for="(item, index) in itemSelect"
            :key="index"
            style="display: flex; align-items: center"
          >
            <div
              style="
                width: 100%;
                display: flex;
                align-items: center;
                height: 50px;
              "
              v-if="item.isEditQuantity"
              class="boxEditQuantity"
            >
              <div
                class="boxEditQuantity"
                @click="showQuantityForMainItem(index)"
                style="
                  width: 17%;
                  height: 100%;
                  align-items: center;
                  justify-content: center;
                  display: flex;
                  box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 5px;
                  border-radius: 3px;
                "
              >
                {{ item.quantity }}
              </div>
              <div style="width: 3%" class="boxEditQuantity"></div>
              <div
                class="boxEditQuantity"
                style="
                  width: 48%;
                  height: 100%;
                  align-items: center;
                  justify-content: center;
                  display: flex;
                  box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 5px;
                  border-radius: 3px;
                  color: var(--color) !important;
                "
              >
                <div
                  class="boxEditQuantity"
                  @click="handleQuantityForMainItem(item, 1, index)"
                  style="
                    width: 49%;
                    align-items: center;
                    display: flex;
                    justify-content: center;
                    font-size: 25px;
                  "
                >
                  -
                </div>
                <div
                  class="boxEditQuantity"
                  style="width: 2%; border-left: 1px solid gray; height: 50%"
                ></div>
                <div
                  class="boxEditQuantity"
                  @click="handleQuantityForMainItem(item, 2, index)"
                  style="
                    width: 49%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 25px;
                  "
                >
                  +
                </div>
              </div>
              <div class="boxEditQuantity" style="width: 3%"></div>
              <div
                class="boxEditQuantity"
                style="
                  width: 30%;
                  height: 100%;
                  align-items: center;
                  justify-content: flex-end;
                  display: flex;
                  color: #bdbdbd;
                "
              >
                {{ getMainItemPriceAndModifiersPrice(item) }}
              </div>
            </div>
            <div style="width: 100%; display: flex; align-items: center" v-else>
              <div
                class="boxEditQuantity"
                style="width: 13.5%; height: 40px"
                :style="{ opacity: item.isCanGet == 1 ? 0.3 : 1 }"
                @click="showQuantityForMainItem(index)"
              >
                <div
                  class="boxEditQuantity"
                  style="
                    border: 1px solid #d8dada;
                    text-align: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 100%;
                  "
                >
                  {{ item.quantity }}
                  <span
                    class="material-icons color-green md-30 boxEditQuantity"
                    style="font-size: 15px; margin-left: 5px"
                    >keyboard_arrow_down</span
                  >
                </div>
              </div>
              <div style="width: 3%"></div>
              <div
                style="
                  width: 83.5%;
                  display: flex;
                  border-bottom: 0.5px solid #d8dada;
                  padding-top: 10px;
                  padding-bottom: 10px;
                "
              >
                <div style="width: 20%; align-items: center; display: flex">
                  <img :src="item.image" style="width: 100px; height: 100px" />
                </div>
                <div
                  style="
                    width: 80%;
                    align-items: center;
                    justify-content: center;
                    display: flex;
                  "
                >
                  <div class="div-items" style="position: relative">
                    <div
                      class="item-name"
                      style="
                        width: 70%;
                        line-height: normal;
                        word-break: break-word;
                      "
                    >
                      <!-- <span
                        style="
                          display: -webkit-box;
                          -webkit-box-orient: vertical;
                          overflow: hidden;
                          -webkit-line-clamp: 2;
                        "
                        :style="{ opacity: item.isCanGet == 1 ? 0.3 : 1 }"
                        >{{ item.description }}</span
                      > -->
                      <span
                        style="
                          display: -webkit-box;
                          -webkit-box-orient: vertical;
                          overflow: hidden;
                          -webkit-line-clamp: 2;
                          font-weight: bold;
                        "
                        :style="{ opacity: item.isCanGet == 1 ? 0.3 : 1 }"
                        >{{ item.item_name }}</span
                      >
                      <div
                        v-if="item.isCanGet == 1"
                        style="color: var(--color); font-size: 12px"
                      >
                        NO MORE AVAILABLE
                      </div>
                      <div
                        v-for="(
                          modifierOption, indexModifierOption
                        ) in item.modifier_group"
                        :key="indexModifierOption"
                      >
                        <div
                          style="position: relative; display: grid"
                          class="div-items"
                          v-for="(
                            itemModifierOption, indexItemModifierOption
                          ) in modifierOption.items"
                          :key="indexItemModifierOption"
                        >
                          <div
                            class="div-items"
                            v-for="(
                              itemQuantity, indexQuantity
                            ) in itemModifierOption.quantity"
                            :key="indexQuantity"
                          >
                            <div>
                              <div class="item-name" style="width: 100%">
                                <span
                                  :style="{
                                    opacity:
                                      itemModifierOption.isCanGet == 1
                                        ? 0.3
                                        : 1,
                                  }"
                                  style="font-size: 12px"
                                  >{{ itemModifierOption.item_name }}
                                  <span v-if="itemModifierOption.itemInMog"
                                    >({{
                                      getModifierItemsInModifierItem(
                                        itemModifierOption.itemInMog
                                      )
                                    }})</span
                                  ></span
                                >
                                <div
                                  v-if="itemModifierOption.isCanGet == 1"
                                  style="color: var(--color); font-size: 12px"
                                >
                                  NO MORE AVAILABLE
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="item-price-format"
                      style="
                        width: 30%;
                        opacity: 1;
                        align-items: center;
                        display: flex;
                        justify-content: end;
                      "
                      :style="{ opacity: item.isCanGet == 1 ? 0.3 : 1 }"
                    >
                      <div
                        style="display: grid; position: relative; width: 100%"
                      >
                        <span
                          v-if="
                            !item.hasOwnProperty('none_member_price') ||
                            !item.none_member_price
                          "
                          style="
                            color: #bdbdbd;
                            text-decoration: line-through;
                            font-size: 12px;
                            position: absolute;
                            top: -17px;
                            right: 0px;
                          "
                          >{{ getMainItem(item) }}</span
                        >
                        {{ getMainItemPriceAndModifiersPrice(item) }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <div
          class="w-100 d-flex flex-column align-items-end text-end font-weight-bold"
        >
          <div class="total-section w-75">
            <div class="total-label">Subtotal</div>
            <div class="total-amount">$ {{ calculateTotal().toFixed(2) }}</div>
          </div>
          <div
            class="fee-section w-75"
            v-if="takeAwayOrDineIn == 2 && branch.service_charge_rate > 0"
          >
            <div class="fee-label">Service Charge</div>
            <div class="fee-amount">$ {{ totalDineInFee().toFixed(2) }}</div>
          </div>
          <div class="fee-section w-75" v-if="convertedValueGstRate() > 0">
            <div class="fee-label">{{ Math.abs(branch.gst_rate) }}% GST</div>
            <div class="fee-amount">
              $ {{ convertedValueGstRate().toFixed(2) }}
            </div>
          </div>
          <div class="fee-section w-75" v-if="convertedValueGstRate() == 0">
            <div class="fee-label">{{ Math.abs(branch.gst_rate) }}% GST</div>
            <div class="fee-amount">$ {{ totalGSTInclusive().toFixed(2) }}</div>
          </div>
          <div class="total-section w-75" style="font-size: 24px">
            <div class="total-label" v-if="convertedValueGstRate() > 0">
              Total
            </div>
            <div class="total-label" v-else>Total Incl. GST</div>
            <div class="total-amount">
              $ {{ calculateTotalBeforeDiscount().toFixed(2) }}
            </div>
          </div>
        </div>
        <div class="d-flex mt-3">
          <div class="w-50 me-3 btn-start-over" @click="startOver()">
            Start Over
          </div>
          <div
            class="ms-3 w-50 btn-order-more"
            @click="gotoPath('OrderPageKiosk')"
          >
            Order More
          </div>
        </div>
        <div class="pay-button mt-3" @click="proccedToPayment()">
          Procced to Payment
        </div>
        <div class="d-flex align-items-center justify-content-center mt-3">Powered by <img src="@/assets/logo.jpg" alt="" class="ms-2" style="height: 20px; width: auto;"></div>
      </div>
    </div>
    <div class="position-fixed top-1 end-1 z-index-2 message-success">
      <material-snackbar
        v-if="snackbar === 'danger' || snackbar === 'success'"
        title=""
        date=""
        :description="message"
        :icon="{ component: 'campaign', color: 'white' }"
        :color="snackbar"
        :close-handler="closeSnackbar"
      />
    </div>
    <material-loading :active="loaderActive" />
  </div>
  <ModalNotification
    :message="txtNotification"
    :closeModalNotificationHandler="closeModalNotificationHandler"
  />
  <ModalNotificationSignIn :message="'Please login to use voucher!'" />
  <ModalNotificationOutStockItems :itemSelect="itemSelect" />
</template>

<script>
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import MaterialLoading from "@/components/MaterialLoading.vue";
import $ from "jquery";
import PromocodeService from "../../services/promocode.service";
import OrderService from "../../services/order.service";
import ModalNotification from "@/components/ModalNotification.vue";
import ModalNotificationSignIn from "@/components/ModalNotificationSignIn.vue";
import ModalNotificationOutStockItems from "@/components/ModalNotificationOutStockItems.vue";
import EventBus from "vue3-eventbus";
import { localStorageName } from "@/config/utils";
import Header from "../kiosk/components/Header.vue";

export default {
  name: "My Cart",
  components: {
    MaterialSnackbar,
    MaterialLoading,
    ModalNotification,
    ModalNotificationSignIn,
    ModalNotificationOutStockItems,
    Header,
  },
  data() {
    return {
      snackbar: null,
      error: "",
      paymentMethod: "",
      branch: JSON.parse(
        localStorage.getItem(localStorageName("BRANCH_INFO"), "")
      ),
      cutleryChecked: true,
      memberInfo: JSON.parse(
        localStorage.getItem(localStorageName("memberInfo"), "")
      ),
      memberId: 0,
      loaderActive: false,
      myRewardData: [],
      selectedItem: null,
      selectVoucher: false,
      selectedReward: [],
      takeAwayOrDineIn: 2, // 1 = take away | 2 = dine in
      itemSelect: [],
      rewardSelect: JSON.parse(
        localStorage.getItem(localStorageName("SELECTED_REWARD"), [])
      ),
      take_away_fee: 0,
      selectPaymentMethod: localStorage.getItem(
        localStorageName("PAYMENT_METHOD"),
        1
      ),
      disablePayWithMintyWallet: false,
      openBoxMintyWallet: true,
      totalVoucherDiscount: 0,
      voucher_deducted_format: "",
      voucher_deducted: "",
      isLogined: false,
      isCanPay: 1,
      cartName: "ORDER_INFO",
      txtNotification: "",
      MERCHANT_BRAND_NAME: "",
      messageErrorVoucher: "",
      payLoad: false,
      merchantCode: "",
      branchCode: "",
      guest: localStorage.getItem(localStorageName("NUMBER_OF_GUESTS"), 0),
      showCartUpdatedAfterClearItemsOutStock: false,
      stopOrdering: 0,
      itemIndexSelected: -1,
    };
  },
  mounted() {
    const headerHeight = $("header").height();
    const footerHeight = $(".footer").height() + 30;

    $(".content").css(
      "height",
      `calc(100vh - ${headerHeight + footerHeight}px)`
    );

    EventBus.on("ClearItemsOutStockSuccess", async () => {
      this.isCanPay = 1;
      this.showCartUpdatedAfterClearItemsOutStock = true;

      if (this.memberInfo && this.memberInfo.amount_in_account) {
        var amount_in_account = Number(this.memberInfo.amount_in_account);

        if (Number(amount_in_account.toFixed(2)) < Number(this.finalTotal())) {
          this.selectPaymentMethod = 2;
          this.disablePayWithMintyWallet = true;
        } else {
          this.selectPaymentMethod = 1;
          this.disablePayWithMintyWallet = false;
        }

        localStorage.removeItem(localStorageName("PAYMENT_METHOD"));
        localStorage.setItem(
          localStorageName("PAYMENT_METHOD"),
          this.selectPaymentMethod
        );
      }
      // this.reloadOrder();
    });
  },
  beforeUnmount() {
    window.removeEventListener("popstate", this.clearIsEditQuantity());
  },
  async created() {
    this.cartName = await this.$store.dispatch("cartName");
    this.merchantCode = this.$store.state.merchantCode;
    this.branchCode = this.$store.state.branchCode;
    this.itemSelect = JSON.parse(localStorage.getItem(this.cartName, []));
    localStorage.removeItem(localStorageName("PAYMENT_METHOD"));

    if (!this.itemSelect || this.itemSelect.length == 0) {
      this.gotoPath("OrderPageKiosk");
    }

    if (this.memberInfo) {
      this.memberId = this.memberInfo.id ?? 0;
      this.isLogined = true;
    }

    this.reloadOrder();
  },
  computed: {
    styleObject() {
      return {
        "--color": this.$store.state.color,
      };
    },
  },
  methods: {
    formatPrice(price) {
      return "$ " + parseFloat(price).toFixed(2);
    },
    closeSnackbar() {
      this.snackbar = null;
    },
    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },
    showLoader() {
      this.loaderActive = true;
    },
    hideLoader() {
      this.loaderActive = false;
    },
    calculateTotal() {
      if (this.itemSelect && this.itemSelect.length > 0) {
        return this.itemSelect.reduce((total, product) => {
          if (this.isLogined) {
            total += product.member_price
              ? product.member_price * product.quantity
              : 0;
          } else {
            total += product.price_default
              ? product.price_default * product.quantity
              : 0;
          }

          return total;
        }, 0);
      } else {
        return 0;
      }
    },
    selectReward() {
      if (this.isLogined) {
        this.$router.push({
          name: "MyReward",
          params: {
            merchant_code: this.merchantCode,
            type: this.pathType,
            branch_code: this.branchCode,
            from_cart: true,
          },
        });
      } else {
        $(".btn-notification-sign-in-modal").click();
      }
    },
    finalTotal() {
      return this.calculateTotalAfterGST().toFixed(2);
    },
    checkEnableVoucher() {
      if (this.rewardSelect && this.rewardSelect.length > 0) {
        if (this.rewardSelect[0].order_minimum == 2) {
          if (this.calculateTotal() < this.rewardSelect[0].order_total) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      }
      return false;
    },
    async calculateVoucherPrice() {
      this.showLoader();
      if (
        this.memberInfo &&
        this.rewardSelect &&
        this.rewardSelect.length > 0
      ) {
        let dataForm = {
          branch_code: this.branch.branch_code,
          memberId: this.memberId,
          voucherCode: this.rewardSelect[0].code,
          totalOrder: this.calculateTotal(),
        };

        await PromocodeService.calculateVoucherPrice(dataForm).then(
          (response) => {
            if (response.data.result.isSuccess) {
              this.voucher_deducted_format =
                response.data.data.voucher_deducted_format;
              this.totalVoucherDiscount = response.data.data.voucher_deducted;
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
            this.hideLoader();
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.hideLoader();
          }
        );
      }
    },
    totalDineInFee() {
      // if(this.takeAwayOrDineIn == 2 && this.branch.service_charge_rate > 0) {
      if (this.branch.service_charge_rate > 0) {
        return (
          (this.calculateTotal() - this.totalVoucherDiscount) *
          (this.branch.service_charge_rate / 100)
        );
      } else {
        return 0;
      }
    },
    convertedValueGstRate() {
      const numericValue = parseFloat(this.branch.gst_rate);

      if (numericValue > 0) {
        // exclusive
        return (
          (this.calculateTotal() -
            this.totalVoucherDiscount +
            this.totalDineInFee()) *
          (numericValue / 100)
        );
      } else {
        // inclusive
        return 0;
      }
    },
    calculateTotalAfterGST() {
      if (this.itemSelect && this.itemSelect.length > 0) {
        if (parseFloat(this.branch.gst_rate) < 0) {
          // INCLUSIVE
          let total = this.itemSelect.reduce((total, product) => {
            if (this.isLogined) {
              total += product.member_price
                ? product.member_price * product.quantity
                : 0;
            } else {
              total += product.price_default
                ? product.price_default * product.quantity
                : 0;
            }

            return total;
          }, 0);

          total += this.totalDineInFee() - this.totalVoucherDiscount;
          return total > 0 ? total : 0;
        } else {
          // EXCLUSIVE
          let total = this.itemSelect.reduce((total, product) => {
            if (this.isLogined) {
              total += product.member_price
                ? product.member_price * product.quantity
                : 0;
            } else {
              total += product.price_default
                ? product.price_default * product.quantity
                : 0;
            }

            return total;
          }, 0);

          total +=
            this.totalDineInFee() +
            this.convertedValueGstRate() -
            this.totalVoucherDiscount;
          return total > 0 ? total : 0;
        }
      } else {
        return 0;
      }
    },
    calculateTotalBeforeDiscount() {
      if (this.itemSelect && this.itemSelect.length > 0) {
        if (parseFloat(this.branch.gst_rate) < 0) {
          // INCLUSIVE
          let total = this.itemSelect.reduce((total, product) => {
            if (this.isLogined) {
              total += product.member_price
                ? product.member_price * product.quantity
                : 0;
            } else {
              total += product.price_default
                ? product.price_default * product.quantity
                : 0;
            }

            return total;
          }, 0);

          total += this.totalDineInFee();
          return total > 0 ? total : 0;
        } else {
          // EXCLUSIVE
          let total = this.itemSelect.reduce((total, product) => {
            if (this.isLogined) {
              total += product.member_price
                ? product.member_price * product.quantity
                : 0;
            } else {
              total += product.price_default
                ? product.price_default * product.quantity
                : 0;
            }

            return total;
          }, 0);

          total += this.totalDineInFee() + this.convertedValueGstRate();
          return total > 0 ? total : 0;
        }
      } else {
        return 0;
      }
    },
    totalGSTInclusive() {
      var gstConverted = Math.abs(this.branch.gst_rate) / 100;
      return (
        (this.calculateTotalAfterGST() / (1 + gstConverted)) * gstConverted
      );
    },
    async reloadOrder() {
      if (this.payLoad) {
        return;
      }

      this.payLoad = true;

      if (this.stopOrdering) {
        this.txtNotification =
          "The restaurant is currently not available for ordering, please try again later";
        $(".btn-notification-modal").click();
        this.payLoad = false;
      } else {
        this.messageErrorVoucher = "";
        this.showLoader();
        let dataForm = {
          items: JSON.parse(localStorage.getItem(this.cartName, [])),
          branch_code: this.$store.state.branchCode,
          member_id: this.memberId,
          promocode: this.rewardSelect,
          total_item: this.calculateTotal(),
        };

        await OrderService.reload(dataForm).then(
          async (response) => {
            if (response.data.result.isSuccess) {
              let dataResponse = response.data.data;
              this.MERCHANT_BRAND_NAME = dataResponse.merchant_brand_name ?? "";
              this.itemSelect = dataResponse.items;
              if (dataResponse.isCanPay == 0) {
                $(".btn-notification-out-stock-items-modal").click();
              } else {
                $(".btn-close-out-stock-items").click();
              }
              this.isCanPay = dataResponse.isCanPay;
              localStorage.removeItem(this.cartName);
              localStorage.setItem(
                this.cartName,
                JSON.stringify(this.itemSelect)
              );
              await this.calculateVoucherPrice();
              this.payLoad = false;
              this.hideLoader();
            } else {
              let warningMessage = response.data.result.message;
              var prefix = "prefixVoucherError-";
              if (warningMessage.startsWith(prefix)) {
                this.messageErrorVoucher = warningMessage.substring(
                  prefix.length
                );
              } else {
                this.snackbar = "danger";
                this.message = response.data.result.message;
                this.setTimeOutNotification();
              }
              $(".btn-close-out-stock-items").click();
              this.payLoad = false;
              this.hideLoader();
            }
          },
          (error) => {
            $(".btn-close-out-stock-items").click();
            this.snackbar = "danger";
            this.message = error;
            this.setTimeOutNotification();
            this.hideLoader();
            this.payLoad = false;
          }
        );
      }
    },
    getMainItemPriceAndModifiersPrice(item) {
      var priceMainAndModifer = 0;
      priceMainAndModifer = this.isLogined
        ? item.member_price_no_modifier_option * item.quantity
        : item.price_default_no_modifier_option * item.quantity;

      var totalPriceModifer = 0;
      item.modifier_group.forEach((group) => {
        group.items.forEach((itemModifier) => {
          totalPriceModifer +=
            itemModifier.price_default * itemModifier.quantity;
        });
      });

      return this.formatPrice(
        priceMainAndModifer + totalPriceModifer * item.quantity
      );
    },
    getMainItem(item) {
      var priceMainAndModifer = 0;
      priceMainAndModifer = this.isLogined
        ? item.price_default_no_modifier_option * item.quantity
        : 0;

      var totalPriceModifer = 0;
      item.modifier_group.forEach((group) => {
        group.items.forEach((itemModifier) => {
          totalPriceModifer +=
            itemModifier.price_default * itemModifier.quantity;
        });
      });

      return priceMainAndModifer > 0
        ? this.formatPrice(
            priceMainAndModifer + totalPriceModifer * item.quantity
          )
        : "";
    },
    showQuantityForMainItem(itemIndex) {
      this.clearIsEditQuantity();
      if (itemIndex !== -1) {
        if (!this.itemSelect[itemIndex].isEditQuantity) {
          this.itemSelect[itemIndex].isEditQuantity = true;
        } else {
          this.itemSelect[itemIndex].isEditQuantity = false;
        }
      }
    },
    async handleQuantityForMainItem(mainItem, type, itemIndex) {
      // 1 is - | 2 is +
      if (itemIndex !== -1 && type == 1) {
        if (this.itemSelect[itemIndex].quantity === 1) {
          // If quantity is going to be decremented to 0, show confirm dialog
          this.itemIndexSelected = itemIndex;
          this.txtNotification =
            "Are you sure you want to remove this item '" +
            mainItem.item_name +
            "' from your cart?";
          $(".btn-notification-modal").click();
        } else {
          this.decrementItemQuantity(itemIndex);
        }
      } else if (itemIndex !== -1 && type == 2) {
        this.itemSelect[itemIndex].quantity =
          this.itemSelect[itemIndex].quantity + 1;
        localStorage.removeItem(this.cartName);
        localStorage.setItem(this.cartName, JSON.stringify(this.itemSelect));
      }
      this.showLoader();
      await this.calculateVoucherPrice();
      this.hideLoader();
    },

    closeModalNotificationHandler() {
      if (this.itemIndexSelected >= 0) {
        let itemIndex = this.itemIndexSelected;
        this.decrementItemQuantity(itemIndex);
        this.itemIndexSelected = -1;
      }
    },

    decrementItemQuantity(itemIndex) {
      this.itemSelect[itemIndex].quantity =
        this.itemSelect[itemIndex].quantity - 1;
      if (this.itemSelect[itemIndex].quantity === 0) {
        this.itemSelect.splice(itemIndex, 1);
      }
      localStorage.removeItem(this.cartName);
      localStorage.setItem(this.cartName, JSON.stringify(this.itemSelect));
    },

    clearIsEditQuantity() {
      this.itemSelect.forEach((item) => {
        delete item.isEditQuantity;
      });
      var dataItems = JSON.parse(localStorage.getItem(this.cartName, []));
      localStorage.removeItem(this.cartName);

      if (dataItems && dataItems.length) {
        dataItems.forEach((itemObject) => {
          delete itemObject.isEditQuantity;
        });
        localStorage.setItem(this.cartName, JSON.stringify(dataItems));
      }
    },
    hideDiv(event) {
      const clickedElement = event.target;

      // Check if the clicked element doesn't have the class 'boxEditQuantityNormal'
      if (
        clickedElement &&
        !clickedElement.classList.contains("boxEditQuantity")
      ) {
        this.clearIsEditQuantity();
      }
    },
    gotoPath(name) {
      this.$router.push({
        name: name,
        params: {
          merchant_code: this.merchantCode,
          branch_code: this.branchCode,
        },
      });
    },
    async checkBranchStatus() {
      var dataForm = {
        branch_code: this.branchCode,
      };

      await OrderService.checkbranchStatus(dataForm).then(
        (response) => {
          this.stopOrdering = response.data.data.stop_ordering;
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.setTimeOutNotification();
          this.hideLoader();
        }
      );
    },
    getModifierItemsInModifierItem(item) {
      var name = [];
      item.modifier_group.forEach((group) => {
        group.items.forEach((itemModifier) => {
          name.push(itemModifier.item_name);
        });
      });
      return name && name.length > 0 ? name.join(", ") : "";
    },
    startOver() {
      localStorage.removeItem(this.cartName);
      this.gotoPath("LandingPageKiosk");
    },
    proccedToPayment() {
      this.$router.push({
        name: "PaymentSelectionPageKiosk",
        params: {
          merchant_code: this.merchantCode,
          branch_code: this.branchCode,
          total: this.calculateTotalBeforeDiscount().toFixed(2),
        },
      });
    },
  },
};
</script>
<style scoped>
.container {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content {
  height: calc(100% - 180px);
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 92px;
  padding-left: 15px;
  padding-right: 15px;
  border-bottom: 0.5px solid #d3d3d3;
  padding-bottom: 10px;
  overflow-y: auto;
}

.content::-webkit-scrollbar {
  display: none;
}

.card-body {
  padding: 0 !important;
}

.text-end {
  color: black;
  width: 20%;
  text-align: end;
}

.item-name,
.modifier-name {
  width: 65%;
  color: black;
}

.total-section,
.fee-section {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
}

.total-label,
.fee-label {
  color: black;
  width: 65%;
}

.total-amount,
.fee-amount {
  color: black;
  width: 35%;
}

.voucher-container {
  width: 100%;
  height: 100%;
  background: #eb472f;
  border-radius: 3px;
  position: relative;
}

.voucher-text {
  color: white;
  font-size: 12px;
  font-weight: 600;
}

.grid-container {
  display: grid;
}

.disabled-pay-button {
  /* opacity: 0.5; */
  padding: 10px 10px;
  background: white;
  color: gray;
  font-size: 18px;
  font-weight: 500;
  border: 0.5px solid gray;
  text-align: center;
  border-radius: 10px;
}

.pay-button {
  padding: 10px 10px;
  background: #fbc743;
  color: #000;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  border-radius: 10px;
  font-weight: bold;
}

.div-order-items {
  width: 100%;
  display: inline-flex;
  margin-top: 8px;
}

.div-items {
  width: 100%;
  display: inline-flex;
}

.item-name {
  width: 65%;
  color: black;
}

.item-price-format {
  width: 30%;
  text-align: right;
  color: black;
}

.color-green {
  color: green !important;
}

.md-30 {
  font-size: 30px;
}

.footer {
  width: 100%;
  position: fixed;
  bottom: 0;
  padding: 15px;
  padding-bottom: 5px;
  width: 100%;
  max-width: 1200px;
  left: 50%;
  transform: translate(-50%);
}

.btn-start-over,
.btn-order-more {
  border: 1px solid #d3d3d3;
  padding: 10px 0;
  text-align: center;
  font-weight: bold;
  border-radius: 10px;
}
</style>
