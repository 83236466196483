<template>
  <div class="page-header align-items-start min-vh-100" @click="gotoOrder()">
    <swiper
      :slides-per-view="1"
      :space-between="0"
      :loop="true"
      :autoplay="{
        delay: 3000,
        disableOnInteraction: false,
      }"
      :pagination="{
        clickable: true,
        el: '.swiper-pagination',
      }"
    >
      <swiper-slide>
        <div class="slider">
          <img
            src="https://live.staticflickr.com/65535/49966783866_874962f9f9_k.jpg"
            alt="error"
          />
        </div>
      </swiper-slide>
    </swiper>
    <div class="swiper-pagination"></div>
    <div class="order-now">
      <div class="text-center">
        <div>TOUCH TO START</div>
        <div>Order Here</div>
      </div>
    </div>
    <div class="estimated-waiting-time">
      <div class="text-center">
        <div>Estimated Waiting Time</div>
        <div>10 mins</div>
      </div>
    </div>
  </div>
  <ModalSignIn></ModalSignIn>
  <material-loading :active="loaderActive" />
</template>

<script>
import { mapMutations } from "vuex";
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { Pagination } from "swiper";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import MaterialLoading from "@/components/MaterialLoading.vue";
import { localStorageName } from "@/config/utils";
import ModalSignIn from "../../views/kiosk/components/ModalSignIn.vue";
import $ from "jquery";

SwiperCore.use([Pagination]);

export default {
  name: "Cover",
  components: {
    Swiper,
    SwiperSlide,
    MaterialLoading,
    ModalSignIn,
  },
  data() {
    return {
      loaderActive: false,
      message: "",
      snackbar: null,
      isWaitingTime: true,
      prefixUrl: "",
      branch: JSON.parse(
        localStorage.getItem(localStorageName("BRANCH_INFO"), "")
      ),
      merchantCode: "",
      branchCode: "",
      slidesLandingPage: [],
      memberInfo: JSON.parse(
        localStorage.getItem(localStorageName("memberInfo"), "")
      ),
    };
  },
  beforeMount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  async created() {},
  mounted() {
    this.merchantCode = this.$store.state.merchantCode;
    this.branchCode = this.$store.state.branchCode;
  },
  computed: {
    styleObject() {
      return {
        "--color": this.$store.state.color,
      };
    },
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),

    gotoOrder() {
      this.$router.push({
        name: "OrderPageKiosk",
        params: {
          merchant_code: this.merchantCode,
          branch_code: this.branchCode,
        },
      });
    },

    gotoSignIn() {
      $(".btn-show-modal-signin").click();
    },

    closeSnackbar() {
      this.snackbar = null;
    },

    showLoader() {
      this.loaderActive = true;
    },

    hideLoader() {
      this.loaderActive = false;
    },

    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },
  },
};
</script>

<style scoped>
.page-header {
  position: relative;
  background-color: var(--color);
  padding-bottom: 0 !important;
}

.order-now,
.estimated-waiting-time {
  display: flex;
  justify-content: center;
  width: 100%;
  position: absolute;
  top: 80%;
  transform: translate(0, -50%);
  z-index: 1;
}

.order-now > div {
  background: #ffffff;
  color: #222;
  border-radius: 0px;
  padding: 15px 25px;
  font-size: 12px;
  text-transform: unset !important;
  min-width: 200px;
  width: min-content;
}

.order-now > div div:first-child {
  font-size: 16px;
  font-weight: bold;
}

.estimated-waiting-time {
  top: calc(80% + 75px);
}

.estimated-waiting-time > div {
  font-size: 11px;
  background: #009432;
  color: #ffffff !important;
  text-transform: unset;
  font-weight: normal;
  border-radius: 0px;
  min-width: 200px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.slider,
.slider img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}
</style>
